import React, { useState, useEffect, useRef } from "react";
import { Sidebar } from "../list/sidebarMenu/sidebarMenu";
import { Container, Wrapper } from "./List.styles";
import { useLocation } from "react-router-dom";
import { es } from 'date-fns/locale';
import {
  complete,
  getInfoStudent,
  classList,
  userInfo,
  recoveryRPAjson,
  search_complete,
  search_date_complete
} from "../../requests/Data";

import { useHistory } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch,faTimes } from "@fortawesome/free-solid-svg-icons";
import Tooltip from '@material-ui/core/Tooltip';
import Swal from 'sweetalert2';
import axios from "axios";
import Processescomplete from "../../components/processes/Processescomplete";
import { AiFillFileZip } from "react-icons/ai";
import Papa from 'papaparse';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const Listcomplete = () => {
  const [list, setList] = useState([]);
  const [classes, setClasses] = useState([]);

  const location = useLocation();
  const history = useHistory();

  const [offset, setOffset] = useState(0);
  const [offsetDate, setOffsetDate] = useState(0);

  const [selectedDocuments, setSelectedDocuments] = useState<string[]>([]);
  const [searchId, setSearchId] = useState("");
  const [showClearIcon, setShowClearIcon] = useState(false);
  const [isOpen, setisOpen] = useState(false);
  const [superUser, setSuperUser] = useState(Boolean);
  const [email, setEmail] = useState<string | null>(null); 
  const [searchPending, setSearchPending] = useState<any[]>([]);
  const [hasSearched, setHasSearched] = useState(false);
  const [isSearchCleared, setIsSearchCleared] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isFiltered, setIsFiltered] = useState(false);
  const [isDateEnd, setIsDateEnd] = useState(false);
  const [isPageChange, setIsPageChange] = useState(false);
  const [showAlert, setShowAlert] = useState(true);

  
  const GetPendingProcess = async () => {
    setSelectedDocuments([]);
    const clientProcess = location.state;
    const process5 = { ...clientProcess, ...{ offset: offset, limit: 6 } };

    const [processList, classesList] = await axios.all([
      complete(process5),
      classList(clientProcess.customer),
    ]);

    setList(await processList.data);
    setClasses(await classesList.data);
    const currentPage = location.state?.currentPage || 1;
    const currentPageElement = document.querySelector(`.tables:nth-child(${currentPage})`);
    if (currentPageElement) {
      onClick({ target: currentPageElement });
    }
  };

  useEffect(() => {
    GetPendingProcess();
  }, [offset]);

  const handleClick = async (id: string) => {
    const req = await getInfoStudent(id);
    const data=await req.data;
    const studenInfo = data.related_json_node;
    const process_status_detail=data.process_status_detail;
    req.status === 200
      ? history.push(`/Completlist/${id}`, {
          json: studenInfo,
          ui_request: id,
          classes: classes,
          process_status_detail:process_status_detail
        })
      : alert(`Estudiante ${id} esta siendo procesado por otro usuario`);
  };

  const onClick = (e: any) => {
    const value = e.target;
    if (searchPending[1] > 0 ) {
      setOffsetDate((value.innerText - 1)* 6);
    }else{
      setOffset((value.innerText - 1) * 6);
    }
    localStorage.setItem('paginaActual', value.innerText);
    document
      .getElementsByClassName("selectedTable")[0]
      .classList.remove("selectedTable");
    value.classList.add("selectedTable");
    history.replace(location.pathname, { ...location.state, currentPage: value.innerText });
    setIsPageChange(true);
  };
 
  const numberOfRecords = searchPending[1] > 0 ? parseInt(searchPending[1]) : parseInt(list[1]);
  const recordsPerPage = 6;
  const numberOfPages = Math.floor(numberOfRecords / recordsPerPage) + (numberOfRecords % recordsPerPage !== 0 ? 1 : 0);
 
  const tables = Array.from(
  { length: numberOfPages },
  (object, index) => (
    <p
      key={index}
      className={index !== 0 ? "tables" : "selectedTable tables"}
      onClick={onClick}
    >
      {index + 1}
    </p>
  )
);

 const handleSearch = async () => {
  const search = searchId.trim();

  if (search === "") {
    Swal.fire({
      text: `Por favor, ingresa el Id del documento`,
      icon: "question",
      timer: 3000,
      showConfirmButton: false,
    });
    return;
  }

  if (search.length < 3) {
    Swal.fire({
      text: `Por favor, ingresa al menos 3 caracteres para buscar el documento`,
      icon: "question",
      timer: 3000,
      showConfirmButton: false,
    });
    return;
  }

  const clientProcess = location.state;
  const process5 = {...clientProcess,...{limit: 6}, search: search};

  const searchAll = await search_complete(process5);

  setSearchPending(searchAll.data);
  setHasSearched(true); 
  setIsSearchCleared(false);
}

  useEffect(() => {
    if (hasSearched && !isSearchCleared && searchId.trim() !== "") {
      if (searchPending && Object.values(searchPending)[1] > 0) {
        Swal.fire({
          text: `Se encontro el ID que estaba buscando`,
          icon: "success",
          timer: 3000,
          showConfirmButton: false,
        });
        setShowClearIcon(true);
      } else if (searchPending) {
        Swal.fire({
          text: `No se encontro el ID que estaba buscando`,
          icon: "warning",
          timer: 3000,
          showConfirmButton: false,
        });
        setShowClearIcon(true);
      }
    }
  }, [searchPending, hasSearched, isSearchCleared, searchId]);
  console.log(searchPending);


  useEffect(() => {
    if (searchId.trim() === "") {
      setSearchPending([]);
    
    }
  }, [searchId]);
  
  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  function limpiarBuscador() {
    setSearchId('');
    setIsSearchCleared(true);
    setShowClearIcon(false);
  }

  const handleclearDate = () => {
    setIsFiltered(false);
    setSearchPending([]);
    handleStartDateChange("");
    handleEndDateChange("");
  }

  const handleClear = () => {
    setSearchId("");
    setShowClearIcon(false);
    setIsSearchCleared(true);
  };

  const handleFormSubmit = (e:any) => {
    e.preventDefault();
    handleSearch();
  };


  const allow_zc = window.__RUNTIME_CONFIG__.ALLOW_CAPTURE;
  const allow_delete = window.__RUNTIME_CONFIG__.ALLOW_DELETE;

  function flattenObject(obj: any) {
    const flattened: any = {};
  
    Object.keys(obj).forEach((key) => {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
            const innerObj = flattenObject(obj[key]);
            Object.keys(innerObj).forEach((innerKey) => {
              flattened[`${key}_${innerKey}`] = innerObj[innerKey];
          });
  
      } else {
        flattened[key] = obj[key];
      }
  });
  return flattened;
}
  
const IsCheckedZip = (e: any, id: string) => {
  if (e.target.checked) {
    setSelectedDocuments([...selectedDocuments, id]);
  } else {
    setSelectedDocuments(selectedDocuments.filter(documentId => documentId !== id));
  }
};


  const createCsvBlob = async(ui_request:string): Promise<Blob> => {
    try {
        const response = await recoveryRPAjson(ui_request);
  
        let responseData;
        if (typeof response === 'string') {
            responseData = JSON.parse(response);
        } else {
            responseData = response;
        }
        const flattenedData = flattenObject(responseData); 
        const csvData = Papa.unparse([flattenedData], {quotes: true});
        return new Blob(['\ufeff' + csvData], {type: 'text/csv;charset=utf-8;'});
    } catch (error) {
        console.error("Error creating data: ", error);
        return new Blob(); 
    }
};

const downloadMultipleCsvAsZip = async (ui_requests: Array<string>) => {
  const recordsPerLine = 5;
  let concatenatedCsv = '';
  let previousHeaders = null;

  for (let i = 0; i < ui_requests.length; i++) {
    const csvData = await createCsvBlob(ui_requests[i]);
    const csvText = await csvData.text();

    let lines = csvText.split('\n');
    let headers = lines[0];  
    let records = lines.slice(1);

    if (previousHeaders === null) {  
      concatenatedCsv += `${headers.normalize("NFD")}\n`;
    } else if (headers !== previousHeaders) {
      concatenatedCsv += `\n${headers.normalize("NFD")}\n`;
    }
    previousHeaders = headers; 
    concatenatedCsv += records.map(record => record.normalize("NFD")).join('\n') + '\n';
  }

  const timeStamp = new Date().getTime(); 
  const filename = `Documentos_Descargados_${timeStamp}.csv`; 
  const blob = new Blob(['\ufeff' + concatenatedCsv], {type: 'text/csv;charset=utf-8;'});
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename; 
  a.click();
};


const handleDownloadClick = () => {
  if (selectedDocuments.length > 1) {
    Swal.fire({
      title: 'Confirmar descarga',
      text: 'Ten en cuenta que solo los documentos pueden ser descargados en formato CSV. ¿Estás seguro de que deseas proceder?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, descargar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        downloadMultipleCsvAsZip(selectedDocuments);
        setSelectedDocuments([]);
      }
    });
  } else {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Necesitas seleccionar más de un documento para descargar',
    });
  }
};


  const fetchUserInfo = async () => {
    const response = await userInfo();
    setEmail(response.data.email)
    setSuperUser(response.data.is_superuser)
  }

  useEffect(() => {
      fetchUserInfo();
  }, []);


  function formatToDesiredDate(inputDate: any, endOfDay: boolean = false) {
    let date = new Date(inputDate);
    let yyyy = date.getUTCFullYear();
    let mm = String(date.getUTCMonth() + 1).padStart(2, '0'); // Meses en JS son de 0-11
    let dd = String(date.getUTCDate()).padStart(2, '0');
    let time = endOfDay ? '23:59:59' : '00:00:00';
  
    return `${yyyy}-${mm}-${dd} ${time}`;
}  



const handleStartDateChange = (date: any) => {
  setStartDate(date); 
  setIsDateEnd(true);
  setIsFiltered(false);
}

const handleEndDateChange = (date: any) => {
  setEndDate(date);
  setIsFiltered(false);
}


  const handleSumitDate =  async () => {
    const formattedStart = formatToDesiredDate(startDate);
    const formattedEnd = formatToDesiredDate(endDate, isDateEnd);
    const clientProcess = location.state;
    const process5 = { ...clientProcess,...{ offset: offsetDate, limit: 6 }, startTime:formattedStart, endTime:formattedEnd};
    

    try{
      const response = await search_date_complete(process5);
      setSearchPending(response.data);
      setIsFiltered(true);
      if (showAlert) { 
        if (response.data && Array.isArray(response.data) && response.data[1] > 0) {
        Swal.fire({
            text: `Se encontraron los documentos para las fechas especificadas.`,
            icon: "success",
            timer: 3000,
            showConfirmButton: false,
        });
    } else {
        Swal.fire({
            icon: 'error',
            text: `No se encontraron los documentos para las fechas especificadas.`,
            timer: 3000,
            showConfirmButton: false,
        });
      }
      setShowAlert(false);
    }
  }catch (error) {
      console.error("Error buscando documentos:", error);
  }
}

  useEffect(() => {
    setShowAlert(true); 
  }, [startDate, endDate]);

  const initialRender = useRef(true);

  useEffect(() => {
    if (!initialRender.current) {
      handleSumitDate();
    } else {
      initialRender.current = false;
    }
  }, [offsetDate]);


  return (
    <Container className={isOpen? "sidebarState activate": ""}>
        <Sidebar isOpen={isOpen} setisOpen={setisOpen} propsEmail={email ?? ''}/> 
      <Wrapper>
      

      <h1 className="complete">Procesos Completados</h1>

    <div className="relative-position">
     
      {superUser === true ? (
      <>
        {selectedDocuments.length > 1 &&(
          <Tooltip title="Borrar">
            <AiFillFileZip className="download-icon" onClick={handleDownloadClick} />
          </Tooltip>
        )}
      </>
      ) : (
        <React.Fragment>{""}</React.Fragment>
      )}

    <form onSubmit={handleFormSubmit} className="d-flex justify-content-end mb-3">
    <button
          type="button"
          className="btn-clear"
          onClick={limpiarBuscador}
          title="Borrar búsqueda"
        >
          Borrar búsqueda
    </button>

      <div className="search-input">
          <input
            type="text"
            value={searchId}
            onChange={(e) => setSearchId(e.target.value)}
            placeholder="Buscar por ID"
            onKeyPress={handleKeyPress}
            className="form-control"
          />
          {showClearIcon ? (
            <FontAwesomeIcon
            icon={faTimes}
            className="search-icon"
            onClick={handleClear}
          />
          ):(
            <FontAwesomeIcon
            icon={faSearch}
            className="search-icon"
            onClick={handleSearch}
        />
          )}
      </div>
    </form>
    <div className="date-picker-container">
      <div className="start-date-picker">
          <DatePicker
              onChange={handleStartDateChange}
              selected={startDate}
              dateFormat="yyyy-MM-dd"
              locale={es}
              placeholderText="fecha de inicio"
              className="form-control-date"
          />
      </div>
      <div className="end-date-picker">
          <DatePicker
              onChange={handleEndDateChange}
              selected={endDate}
              dateFormat="yyyy-MM-dd"
              locale={es}
              placeholderText="Fecha de Fin"
              className="form-control-date"
          />
      </div>
      { isFiltered !== false ? (
          <button
          type="button"
          className="btn-date"
          onClick={handleclearDate}
          title="Filtrar búsqueda"
          >
          Borrar Filtrado
          </button>
        ) : (
          <button
          type="button"
          className="btn-date"
          onClick={handleSumitDate}
          title="Filtrar búsqueda"
          >
          Filtrar Fecha
        </button>
      )}
    </div>
  </div>


        {(Object.values(list)[0] && (Object.values(list)[0] as any[]).length > 0) && (
          <Processescomplete
            list={(searchPending.length > 0 && (Object.values(searchPending)[0] as any[]).length > 0) ? Object.values(searchPending)[0] as any[] : Object.values(list)[0] as any[]}
            onEdit={handleClick}
            IsCheckedZip={IsCheckedZip}
            dList={selectedDocuments}
            isSuperUser={superUser}
          />
        )}

        <div className="pagination">{tables}</div>
        
      </Wrapper>
      
    </Container>
  );
  
};
export default Listcomplete;