import React,{MouseEvent} from "react";
import styled from "styled-components";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = {
  text: string;
  bcolor: string;
  icon?: IconProp;
  color?: string;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean; 
};

const Button = ({ icon, bcolor, color = "white", text, onClick ,disabled}: Props) => {
  return (
    <ButtonStyle bcolor={bcolor} color={color} onClick={onClick} disabled={disabled}>
      {text}
      {icon ? <FontAwesomeIcon icon={icon} className="icon" /> : ""}
    </ButtonStyle>
  );
};

export default Button;

const ButtonStyle = styled.button<{ bcolor: string; color: string }>`
  padding: 0.75rem 5rem;
  font-size: 1.25rem;
  margin-bottom: 1rem;

  cursor: pointer;
  border: none;
  border-radius: 0.75rem;
  background-color: ${(props) => props.bcolor};
  position: relative;
  color: ${(props) => props.color};

  .icon {
    position: absolute;
    right: 2rem;
    font-size: 1.25rem;
  }
  
  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
    background-color: grey;
  }
  
`;
