import React,{useState,createContext,useContext} from 'react';

export const authToken = createContext<any>('');
// export const GlobalToken = () => useContext(authToken)


export const reclassifyClasses =createContext({})
export const GlobalClasses = () => useContext(reclassifyClasses)


// export const UserProvider = (e:any) => {
  
//     return (
//       <reclassifyClasses.Provider value={e}/>
//     );
//   };

