import React from "react";
import CentralBox from "../../components/central_box/CentralBox";
import { useHistory } from "react-router-dom";
import { verify_user } from "../../requests/Data";
import Swal from 'sweetalert2';

const VerifyUser = () => {
  const form = [
    ["Correo", "envelope", "email"]
  ];

  const buttons = ["Verificar cuenta"];

  const history = useHistory();

  const VerifySubmit = async (user: any, button: string) => {
    ["Verificar cuenta","Enviar"].includes(button) ? VeriticatedSudmit(user): history.push("/");
  };

  const VeriticatedSudmit = async (user: any) => {
    const valid = () => {
      history.push("/");
      Swal.fire({
        text: `El usuario ${user.Correo} fue verificado Satisfactoriamente`,
        icon: "success",
        timer: 3000,
        showConfirmButton: false,
        width: 500,
      });
    };
    const req = await verify_user(user.Correo);
    req.status == 200 ? valid() : Swal.fire({
      text: "Por favor Complete los campos para la verificación",
      icon: "warning",
      timer: 3000,
      showConfirmButton: false,
    });
  };

  return (
    <>
      <CentralBox
        title="Verificación de la cuenta"
        phrase="Ingresa el correo con el que te registraste"
        height="74%"
        buttons={buttons}
        infoForm={form}
        onSubmit={VerifySubmit}
      />
    </>
  );
};

export default VerifyUser;
