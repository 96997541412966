import React,{useState} from 'react'
import {Autocomplete , TextField } from '@material-ui/core';
import {Container,Classes} from './Reclassification.styles'

import {GlobalClasses} from '../../context/Context'


type Props ={
    onClick:(tipo:any)=>void;
}

const Reclasification=({onClick}:Props)=>{

   

    const cc:any=GlobalClasses()
    const [search,setSearch]=useState('')
    const handleChange=(e:any)=>{

        setSearch(e.target.value.toLowerCase())

        
    }
    const options =Object.keys(cc)
    const [selectOption,setSelectOption]=useState(['TODOS'])


   const selectedOption=selectOption[0]!=='TODOS'?selectOption:options
   const selectClasses= selectedOption.map(i=> cc[i].map((j:any)=>j.toLowerCase().search(search)>-1?<p 
   className='classes' onClick={onClick}  >{j}</p> :null))


    return (
        <>
        <Container>

        <Autocomplete
        value={selectOption[0]}
        options={options}
        onInputChange={(e,val) => {
            val?setSelectOption([val]):setSelectOption(['TODOS']);
            (document.getElementById('filled-search')as HTMLInputElement).value='' 
            setSearch('')
        }}
        // id="controllable-states-demo"
        sx={{ width: '30vw' }}
        renderInput={(params) => <TextField {...params} label="DOCUMENTOS" size='small'/>}
        
        className='selectClass'/>

        <TextField
         sx={{ width: '30vw' }}
          id="filled-search"
          label="Buscar Tipo"
          type="search"
          variant="standard"
          onChange={handleChange}
          className='search'
          focused 
          
        />


        <Classes>

        {selectClasses}
        </Classes>
        


</Container>
            
        </>
    )
}

export default Reclasification

