import styled from "styled-components";
import { blue, light_blue } from "../../App.styles";

export const Container = styled.div`
  padding: 3rem 3rem 0rem 1.75rem;
  background-color: #daedf4;
  color: ${blue};
  min-height: 100vh;

  margin: 0;
  display: flex;
  flex-direction: column;

  h3 {
    font-size: 1.5rem;
    margin-bottom: 3rem;
  }
  p {
    display: flex;
    color: ${blue};
    text-decoration: none;
    margin-bottom: 3.5rem;
    padding-bottom: 0.5rem;
    font-size: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  .selectedKeys {
    color: ${light_blue};
    font-family: "Gotham-Bold";
    border-left: 5px solid ${blue};
    padding-left: 0.5rem;
  }

  button {
    font-size: 1.1rem;
    padding: 0.75rem 4rem;
  }
`;
