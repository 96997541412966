import React from 'react'
import styled from 'styled-components'
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { FloatingButton } from "../../components/editing/Editing.styles";
import {  pink } from '../../App.styles';


type Props = {
    image: any
    zoom : number
    showButton: boolean
    
}

const ImgsComplete = ({ image, zoom, showButton }: Props) => { 


    return (
        <Img>
            <TransformWrapper>
                <TransformComponent>
                        <img className='docImage' src={image} alt="Error" style={{'transform':` scale(${zoom}))`}}></img> 
                </TransformComponent>
            </TransformWrapper>
         </Img>
    )
}

export default ImgsComplete

const Img = styled.div`

        img {
            position: relative;
            height: 100%;
            width: 100%;
        }

`;
