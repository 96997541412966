import axios from "axios"
import { za,zInfo } from "./Intances"




const API_URL=window.__RUNTIME_CONFIG__.API_URL

export const pendings = (clientProcess:any)=> za.get('get_assistant_pending_data/',
  { params: clientProcess, headers: {Authorization: "Bearer " + localStorage.getItem('Token')}})
  
export const complete = (clientProcess:any)=> za.get('get_assistant_complete_data/',
{ params: clientProcess, headers: {Authorization: "Bearer " + localStorage.getItem('Token')}})


export const getInfoStudent =(ui_request:string)=> za.get('get_assistant_jsonnew/',
{ params: { 'ui_request': ui_request },headers:{Authorization: "Bearer " + localStorage.getItem('Token')} })


export const modifyInfo=(ui_request:string,managed_json:any)  =>axios.request({ url:`${API_URL}api/v1/add_managed_json/`, method: "POST",
headers: {
  "Content-type": "application/json",
  Authorization: "Bearer " + localStorage.getItem('Token')
  
},
data: JSON.stringify({
  "ui_request": ui_request,"managed_json": JSON.stringify(managed_json)}
)})


export const classList = (id:string)=> za.get('get_classification_list/',
{params:{id_customer:id},headers: {Authorization: "Bearer " + localStorage.getItem('Token')}})


export const processes =()=> za.get('get_processes_by_client',
{params:{id_customer:2},headers: {Authorization: "Bearer " + localStorage.getItem('Token')}})

export const removeList = (ids:any)=> za.delete('delete_assistant_object/',
{data:{ui_request:ids},headers: {Authorization: "Bearer " + localStorage.getItem('Token')}})


export const liberate=(id:string)  =>axios.request({ url:`${API_URL}api/v1/release_process/`, method: "PATCH",
headers: {
  "Content-type": "application/json",
  Authorization: "Bearer " + localStorage.getItem('Token')
  
},
params:{ui_request:id}

}).catch(function (error) {
  return error;
})

export const reclassify=(data:any,id:string,process_status_detail:string)  =>axios.request({ url:`${API_URL}api/v1/reclassify_response/`, method: "POST",
headers: {
  "Content-type": "application/json",
  Authorization: "Bearer " + localStorage.getItem('Token')
  
},
data: JSON.stringify(data
),
params:{customer:2,ui_request:id,process_status_detail:process_status_detail}

}).catch(function (error) {
  return error;
})



export const recoveryRPAjson = (ui_request: string) => za.get('/get_assistant_json/', {
  params:{'ui_request': ui_request},
  headers: {
    "Content-type": "application/json",
    Authorization: "Bearer " + localStorage.getItem('Token')
  }
})
.then((response) =>{
  return response.data;
});


export const userInfo = ()=> zInfo.get('users/me',
  { headers: {Authorization: "Bearer " + localStorage.getItem('Token')}})

export const search_pending = (clientProcess:any)=> za.get('get_search_pending/',
  { params: clientProcess, headers: {Authorization: "Bearer " + localStorage.getItem('Token')}})

export const search_complete = (clientProcess:any)=> za.get('get_search_complete/',
  { params: clientProcess, headers: {Authorization: "Bearer " + localStorage.getItem('Token')}})

export const verify_user = (userEmail: string) => za.get('users/update_user/', {
  params: { userEmail: userEmail },
  headers: {
    "Content-type": "application/json",
  }
})
.then((response) => {
  return { data: response.data, status: response.status };
});

export const search_date_complete = (clientProcess:any)=> za.get('get_search_date_complete/',
  { params: clientProcess, headers: {Authorization: "Bearer " + localStorage.getItem('Token')}})