import React, { useEffect, useContext } from "react";
import CentralBox from "../../components/central_box/CentralBox";
import { useHistory } from "react-router-dom";

import { login } from "../../requests/Intances";

import { authToken } from "../../context/Context";

import { processes } from "../../requests/Data";
import Swal from 'sweetalert2';

const Login = () => {
  const {setToken}  = useContext(authToken);

  const history = useHistory();

  const form = [
    ["Usuario", "envelope", "email"],
    ["Contraseña", "lock", "password"],
  ];
  const buttons = ["Iniciar", "Registrarme"];

  useEffect(() => {
    localStorage.setItem("Token", "");
    setToken(null);
  }, []);

  const loginReq = async (username: string, password: string) => {
    const req = await login(username, password);
    const valid = async () => {
      setToken(await req.data.access_token);
      localStorage.setItem("Token", await req.data.access_token);
      const process = await (await processes()).data;
      process.length === 1
        ? history.push("/List", { customer: 2, process: process[0].id_process })
        : history.push("/Client", process);
    };

    req.status ? valid() : Swal.fire({
      text: "Por Favor Verifique sus Credenciales",
      icon: "warning",
      timer: 3000,
      showConfirmButton: false,
    });
  };

  const LoginSubmit = async (user: any, button: string) => {
    ["Iniciar", "Enviar"].includes(button)
      ? loginReq(user.Usuario, user.Contraseña)
      : history.push("/Register");
  };

  return (
    <>
      <CentralBox
        title="Iniciar Sesión"
        phrase="Aprovecha al máximo nuestros servicios"
        height="76%"
        buttons={buttons}
        tag={["Olvide mi contraseña", "Password"]}
        infoForm={form}
        onSubmit={LoginSubmit}
      />
    </>
  );
};

export default Login;
