import styled from "styled-components";
import { blue, pink } from "../../App.styles";

export const Container = styled.div`
    display: flex;
    background-color: white;
    display:grid;
    grid-template-columns:90px auto;
    transition: all 0.5s;
    &.activate{
      grid-template-columns:270px auto;
    }
    height: 100vh
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 3rem 2rem 2rem 4rem;
  z-index: 1;
  position: relative;
  h1 {
    color: ${blue};
  }
  .pagination {
    display: flex;
    justify-content: center;
    .tables,
    .selectedTable {
      margin: 1.5rem 0.5rem;
      cursor: pointer;
    }
    .selectedTable {
      font-family: "Gotham-Bold";
      color: ${pink};
    }
  }
  .selected-document {
    background-color: rgba(0, 123, 255, 0.5);
  }
  .eliminar {
    width: 0;
    height: 0;
    position: absolute;
    right: 5rem;

    svg {
      font-size: 2.5rem;
    }
  }

  .search-input {
    position: relative;
    margin-left: 5px;
    display: inline-block;
  }
  
  .form-control {
    widwidth: 150px;
    padding-right: 30px; 
  }
  
  .search-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }  

  .Trashing-icon {
    position: absolute;
    right: 0;
    top: -40px;
    cursor: pointer;
    margin-left: 30px; 
    font-size: 20px;
    filter: grayscale(100%);
  }
  .btn-clear{
    margin-left: 10px;
    padding: 0.40rem 0.5rem;
    font-size: 1rem;
    cursor: pointer;
    border: none;
    border-radius: 0.5rem;
  }

  .relative-position{
    position: relative;
  }

  .download-icon {
    position: absolute;
    right: 0;
    top: -40px;
    cursor: pointer;
    margin-left: 30px; 
    font-size: 20px;
    filter: grayscale(100%);
  }

  .complete{
    color: black;
}
  .form-control-date{
    width: 150px; 
    height: 30px;
    text-align: center;
  }

 .date-picker-container{
    position: absolute;
    top: 38%;
    transform: translateY(-50%);
    cursor: pointer;
    display: flex;
    justify-content: space-evenly;
    width: 420px;
  }
 
 .start-date-picker {
    margin-rigth: 5px;
  }

  .btn-date{
    padding: 0.40rem 0.5rem;
    font-size: 1rem;
    cursor: pointer;
    border: none;
    border-radius: 0.5rem;
  }
`;
