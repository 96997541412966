import React, { useState, ChangeEvent, KeyboardEvent, useEffect } from "react";
import { Box, Inputs} from "./CentralBox.styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { pink } from "../../App.styles";
import Button from "../layout/Button";
import { Link } from "react-router-dom";

type Props = {
  title: string;
  phrase: string;

  height: string;
  buttons: string[];

  tag?: string[];
  infoForm?: string[][];
  optionValues?: string[];

  onSubmit: (info: any, i: string) => void;
  showOrganizationField?: boolean;
};

const CentralBox = ({
  title,
  phrase,
  height,
  buttons,
  tag,
  infoForm,
  optionValues,
  onSubmit,
}: Props) => {
  const [info, setInfo] = useState({});
  const [viewPss, setViewPss] = useState(true);
  const ToggleInput = ["password", "url"];
  const [emailError , setEmailError] = useState("")
  const [filteredSuggestions, setFilteredSuggestions] = useState<string[]>([]);
  const [organizationSuggestions, setOrganizationSuggestions] = useState<string[]>([]);
  
  useEffect(()=> {
    setOrganizationSuggestions(['Ilumno','Camara Comercio', 'Preventa']);
  },[]);

  const handleInputChange =(e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const key = e.target.id;
  
    if (key === 'Organizacion' && value.length >= 2) {
      const filtered = organizationSuggestions.filter(
        (suggestion) => suggestion.toLowerCase().indexOf(value.toLowerCase()) > -1
      );
      setFilteredSuggestions(filtered);
    } else if (key === 'Organizacion') {
      setFilteredSuggestions([]);
    }
    setInfo((state: any) => ({ ...state, [key]: value }));
  };
  
  //If there is a tag, link to that page.
  const isTag = tag ? <Link to={tag[1]}>{tag[0]}</Link> : null;

  const ShowPassword = (typeInput: string) => {
    return ToggleInput.includes(typeInput) ? (
      <FontAwesomeIcon
        icon={viewPss ? "eye" : "eye-slash"}
        className="passwordFont"
        onClick={() => setViewPss(!viewPss)}
      />
    ) : null;
  };

  const ChangeFormInfo = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const key = e.target.id;
    setInfo((state: any) => ({ ...state, [key]: value }));
  };

  const handleInputBlur = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const key = e.target.id;
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    
    if (key === "Usuario"||key ==="Correo") { 
      if (!regex.test(value)) { 
        setEmailError("La dirección de correo electrónico no es válida");
      } else { 
        setEmailError(""); 
      }
    }
    setInfo((state: any) => ({ ...state, [key]: value }));
    setFilteredSuggestions([]);
  };

  const ValueOptions = (id: string) => {
    const options = id === 'building' ? filteredSuggestions : optionValues;
    return (
      <datalist id={id}>
        {options && options.map((option: string, index: number) => (
          <option key={index} value={option} />
        ))}
      </datalist>
    );
  };



  const EnterKeyForm = (e: KeyboardEvent) => {
    return e.code === "Enter" ? onSubmit(info, "Enviar") : null;
  };

  

  const inputStructure = infoForm
    ? infoForm.map((object, index) => (
        <Inputs key={index}>
          <FontAwesomeIcon icon={object[1] as IconProp} className="font" />
          <input
            type={
              ToggleInput.includes(object[2])
                ? viewPss
                  ? "password"
                  : "url"
                : object[2]
            }
            placeholder={object[0]}
            list={object[0] === "Organizacion" ? "organizationList" : undefined}
            id={object[0]}
            onChange={ChangeFormInfo}
            onKeyDown={EnterKeyForm}
            onInput={handleInputChange}
            className={object[0] === "Usuario" ? "email-input" : "" + object[0] === "Correo" ? "email-input" : ""}
            onBlur={handleInputBlur}
            
          />
          {ShowPassword(object[2])}
          {object[0] === "Usuario" && emailError && <span className="error">{emailError}</span>}
          {object[0] === "Correo" && emailError && <span className="error">{emailError}</span>}
        </Inputs>
      ))
    : null;


  const buttonsStructure = buttons.map((object, index) => (
    <Button
      bcolor={index ? "white" : pink}
      color={index ? pink : "white"}
      onClick={() => {
        onSubmit(info, object);
      }}
      text={object}
      key={index}
    />
  ));

  return (
    <Box height={height}>
      <div className="wrapper">
        <h1>{title}</h1>
        <p className="phrase">{phrase}</p>
      </div>
      {inputStructure}
      <datalist id="organizationList">
        {filteredSuggestions.map((option : string, index: number) => (
           <option key={index} value={option} />
        ))}
      </datalist>
      <div className="wrapper">
        {isTag}
        {buttonsStructure}
      </div>
    </Box>
  );
};

export default CentralBox;
