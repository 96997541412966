import styled, { createGlobalStyle } from "styled-components";

//Text fonts to be used
import GothamLight from "./assets/fonts/Gotham-Light.otf";
import GothamBook from "./assets/fonts/Gotham-Book.otf";
import GothamBold from "./assets/fonts/Gotham-Bold.ttf";
import GothamBlack from "./assets/fonts/Gotham-Black.otf";

import background from './assets/images/background1.svg'

//Icon fonts to be used
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import {faAngleDown,faFingerprint,faFileAlt,faImage,faUserAlt,faSuitcase,
  faEnvelope,faLock,faSignInAlt, faUsers, faShieldAlt,faExchangeAlt,faEdit, faEye, faEyeSlash, faRedoAlt, faUserPlus,faBuilding,faMinus, faPlus, faSearchPlus, faSearchMinus,faFile,faTimes} from '@fortawesome/free-solid-svg-icons'
library.add(fab, faAngleDown,faFingerprint,faFileAlt,faImage,faUserAlt,faSuitcase,
  faEnvelope,faLock,faSignInAlt,faUsers,faShieldAlt,faExchangeAlt,faEdit,faEye,faEyeSlash,faRedoAlt,faUserPlus,faBuilding,faMinus, faPlus,  faSearchPlus, faSearchMinus, faFile,faTimes)

//Colors to be used
export const blue='#1D5881'
export const pink ='#B83674'
export const light_blue='#2879b1'


const textfonts =[GothamLight, GothamBook, GothamBold, GothamBlack].map(
      (i) => `
  @font-face {
    font-family:${i.split(".")[0].split("/").slice(-1)} ;
    src: url(${i});
  }`
);


export const GlobalStyle = createGlobalStyle`
  ${textfonts};
  html{
    font-size: 12px;
  }

  
  @media screen and (max-width : 900px)
{

  html{
    font-size: 8px;
  }
  }
  @media  (max-height: 600px)
{

  html{
    font-size: 10px;
  }
  }
  @media  (max-height: 500px)
{

  html{
    font-size: 6px;
  }
  }
    
  /* body or yourdiv element
  {
    font:<size>px/em/rm;
  }
}
@media screen and (max-width : 1204px)
{
  body or yourdiv element
  {
    font:<size>px/em/rm;
  }
} */

  /* } */
  * {
    font-family: 'Gotham-Book';
    letter-spacing: 0.025rem;
    box-sizing: border-box;
    margin:0;

  }



`;
export const Container = styled.div`
  display:flex;
  flex-direction:column;
  background-image:url(${background}) ;
  background-size: cover;
  min-height: 100vh;

`;

export const Wrapper = styled.div`
  flex: 1;
  /* min-height: 100vh; */


`;

