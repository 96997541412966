import axios from "axios";

const API_URL=window.__RUNTIME_CONFIG__.API_URL

const ZC=window.__RUNTIME_CONFIG__.ZAT_CAPTURE

export const auth = axios.create({
  baseURL: `${API_URL}auth/`,
  headers: {
    'Content-Type': "application/json",
  }
});
export const za = axios.create({
  baseURL: `${API_URL}api/v1/`,
  headers: {
    "Content-type": "application/json"
  }
});

export const zc = axios.create({
  baseURL: `${ZC}api/v1/`,
  headers: {
    "Content-type": "application/json",
    "Authorization": "Bearer Ilumno"
  }
});
 //TODO esta es la instancia que se utiliza para enviar el reprocesamiento de imagen 
export const zc2 = axios.create({
  baseURL: `${ZC}api/v1/`,
  headers: {
    "Content-type": "multipart/form-data"
  }
});

export const zInfo = axios.create({
  baseURL: `${API_URL}`,
  headers: {
    "Content-type": "application/json"
  }
});

// check this requests....

export const login =(email:string,pss:string)  =>axios.request({ url:`${API_URL}auth/jwt/login`, method: "POST",
headers: { "Content-Type": "application/x-www-form-urlencoded" },
data: JSON.stringify(`grant_type=&username=${email}&password=${pss}&scope=&client_id=&client_secret=`
)}).catch(function (error) {
  return error;
})


