import { auth } from "./Intances"

export const register =(email:string,password:string) =>  auth.post('register',
{"email": email,"password": password,"is_active": true,"is_superuser": false,"is_verified": false
  }) 
  .catch(function (error) {
      return error;
})


export const recover =(email:string)=> auth.post('forgot-password',
{'email':email}).catch(function (error) {
  return error;
})

export const changepss =(token:any,pss:string)=> auth.post('reset-password',
{'token':token,'password':pss}).catch(function (error) {
  return error;
})