import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { FloatingButton } from "../../components/editing/Editing.styles";
import {  pink } from '../../App.styles';


type Props = {
    image: any
    rotate:number
    zoom : number
    showButton: boolean
    reprocessImage: (image: string, rotate:number) => Promise<void>
    onImageLoaded: () => void;
}

const Imgs = ({ image, rotate, zoom, showButton, reprocessImage, onImageLoaded}: Props) => { 
    const [isLoading, setIsLoading] = useState(false);
    const [isImageLoading, setImageLoading] = useState(true);

    const handleReprocess = async (img: string, rot: number) => {
        setIsLoading(true);
        await reprocessImage(img, rot);
        setIsLoading(false);
    };



    return (
        <Img>
            <TransformWrapper>
                <TransformComponent>
                    <div className={`imgContainer ${showButton ? 'censored' : ''}`}>
                        <img className='docImage' src={image} alt="Error" style={{'transform':` scale(${zoom}) rotate(${rotate}deg)`}} onLoad={() => {setImageLoading(false); onImageLoaded();}}></img>
                        
                        {showButton && (
                            <FloatingButton bcolor={pink} text="Reprocesar Documento" onClick={() => handleReprocess(image,rotate)}/>  
                        )}
                    </div>

                    {isImageLoading && (
                            <div className="imageLoadingSpinner">
                                <div className="loadingSpinner"></div>
                                <p>La imagen se está cargando...</p>
                            </div>
                    )}
                </TransformComponent>
            </TransformWrapper>
            {isLoading && (
                <div className="overlay">
                    <div className="loadingAlert">
                        <div className="loadingSpinner"></div>
                        <p>Este documento se está procesando, espere un momento...</p>
                    </div>
                </div>
            )}
         </Img>
    )
}

export default Imgs

const Img = styled.div`
    .imgContainer {
        position: relative;
        height: 45rem;
        width: 42.5rem;

        img {
            position: relative;
            height: 100%;
            width: 100%;
        }

        button {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;
        }

        &.censored::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: rgba(0,0,0,0);
            backdrop-filter: blur(1.5px);
            z-index: 1;
        }
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 4;
    }

    .loadingAlert {
        background-color: white;
        padding: 20px;
        border-radius: 10px;
        text-align: center;
        max-width: 80%;
        box-shadow: 0 0 10px rgba(0,0,0,0.5);
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .loadingSpinner {
        // Estilos para el spinner
        border: 6px solid rgba(255, 255, 255, 0.3);
        border-radius: 50%;
        border-top: 6px solid ${pink};
        width: 50px;
        height: 50px;
        animation: spin 1s linear infinite;
        margin: 0 auto 10px auto;
    }
    
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    } 

    .imageLoadingSpinner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 5;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: rgba(255,255,255,0.7);
        padding: 20px;
        border-radius: 10px;
        text-align: center;
    }

    .imageLoadingSpinner p {
        margin-top: 10px;
    }   
`;
