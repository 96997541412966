import styled from "styled-components";
import { blue,pink } from "../../App.styles";

export const Container = styled.div`
  display: flex;
  background-color: white;
  h2{
    margin: 3rem 2rem;
    span{
      color:${pink}
    }
  }
  
`;

export const Edit = styled.div`
  padding: 3rem 2rem;
  display: flex;
  justify-content: space-between;
`;

