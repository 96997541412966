import React from "react";
import CentralBox from "../../components/central_box/CentralBox";
import { useHistory } from "react-router-dom";
import { changepss } from "../../requests/Users";
import { useLocation } from "react-router-dom";
import Swal from 'sweetalert2';

function ChangePss() {
  const form = [
    ["Contraseña", "lock", "password"],
    ["Confirma tu contraseña", "lock", "password"],
  ];
  const buttons = ["Enviar"];
  const history = useHistory();
  const token = new URLSearchParams(useLocation().search).get("token");

  const ChangePssSubmit = async (e: any) => {
    const valid = () => {
      history.push("/");
      Swal.fire({
        text: "Contraseña cambiada satisfactoriamente",
        icon: "success",
        timer: 3000,
        showConfirmButton: false,
      });
    };

    if (e.Contraseña === '' || e['Confirma tu contraseña'] === '') {
      Swal.fire({
        text: "Las credenciales estan vacias",
        icon: "warning",
        timer: 3000,
        showConfirmButton: false,
      });
      return;
    } else if (e.Contraseña !== e['Confirma tu contraseña']) {
      Swal.fire({
        text: "Las contraseñas no coinciden",
        icon: "warning",
        timer: 3000,
        showConfirmButton: false,
      });
      return;
    }

    const req = await changepss(token, e.Contraseña);
    req.status ? valid() : Swal.fire({
      text: "Se produjo un error. Por favor, inténtalo nuevamente.",
      icon: "warning",
      timer: 3000,
      showConfirmButton: false,
    });
  };

  return (
    <>
      <CentralBox
        title="Cambia tu contraseña"
        phrase="Ingresa la nueva contraseña de tu cuenta"
        height="55%"
        buttons={buttons}
        infoForm={form}
        onSubmit={ChangePssSubmit}
        showOrganizationField={false}
      />
    </>
  );
}
export default ChangePss;
