import React from "react";
import { Foot } from "./Footer.styles";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Footer = () => {
  return (
    <Foot>
      <div className="copyright">© Copyright, 2023</div>
      <div className="footer-links">
        <Link to={{ pathname:"https://zatcapture.co/"}} target="_blank" className="sobre-link">
          SOBRE ZAT
        </Link>
        <Link to={{ pathname:"https://zatcapture.co/#footer"}} target="_blank" className="ayuda-link">
          AYUDA
        </Link>
      </div>
      <div>
      <Link to={{ pathname:"https://www.instagram.com/2nv_sas/?igshid=NTc4MTIwNjQ2YQ%3D%3D"}} target="_blank" className="ayuda-link">
        <FontAwesomeIcon 
          className="social-icons" 
          icon={["fab", "instagram"]} 
        />
      </Link>
      <Link to={{ pathname:"https://www.linkedin.com/company/2nv-sas/mycompany/"}} target="_blank" className="ayuda-link">
        <FontAwesomeIcon
          className="social-icons"
          icon={["fab", "linkedin"]}
        />
      </Link>
      <Link to={{ pathname:"https://www.facebook.com/Usuario2NVSAS/"}} target="_blank" className="ayuda-link">
        <FontAwesomeIcon
          className="social-icons"
          icon={["fab", "facebook-square"]}
        />
      </Link>
      </div>
    </Foot>
  );
};

export default Footer;
