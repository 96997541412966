import React from "react";
import CentralBox from "../../components/central_box/CentralBox";
import { useHistory, useLocation } from "react-router-dom";

import styled from "styled-components";

const NotFound = () => {
  const history = useHistory();
  const location = useLocation();
  const buttons = ["Iniciar Sesión"];

  const NotFoundSubmit = async (e: any) => {
    history.push("/");
  };

  return (
    <NF>
      <CentralBox
        title="Página No Encontrada"
        phrase={location.state ? location.state.error : "Su sesión ha expirado o aún no ha iniciado sesión"}
        height="25%"
        buttons={buttons}
        onSubmit={NotFoundSubmit}
      />
    </NF>
  );
};

export default NotFound;

const NF = styled.div`
  button {
    display: none;
  }
`;
