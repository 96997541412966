import React from "react";
import CentralBox from "../../components/central_box/CentralBox";
import { useHistory } from "react-router-dom";
import { register } from "../../requests/Users";
import Swal from 'sweetalert2';

const CreateUser = () => {
  const form = [
    ["Correo", "envelope", "email"],
    ["Contraseña", "lock", "password"],
  ];

  const buttons = ["Registrarme", "volver al inicio"];

  const history = useHistory();

  const LoginSubmit = async (user: any, button: string) => {
    ["Registrarme","Enviar"].includes(button) ? RegisterSubmit(user): history.push("/");
  };

  const RegisterSubmit = async (user: any) => {
    const valid = () => {
      history.push("/");
      Swal.fire({
        text: `Usuario ${user.Correo} Fue Creado Satisfactoriamente`,
        icon: "success",
        timer: 3000,
        showConfirmButton: false,
        width: 500,
      });
    };
    const req = await register(user.Correo, user.Contraseña);
    req.status ? valid() : Swal.fire({
      text: "Por favor Complete los campos para su Registro",
      icon: "warning",
      timer: 3000,
      showConfirmButton: false,
    });
  };

  return (
    <>
      <CentralBox
        title="Crea una cuenta"
        phrase="Regístrate con tu correo y contraseña"
        height="74%"
        buttons={buttons}
        infoForm={form}
        onSubmit={LoginSubmit}
      />
    </>
  );
};

export default CreateUser;
