import React from "react";
import CentralBox from "../../components/central_box/CentralBox";
import { useHistory } from "react-router-dom";
import { recover } from "../../requests/Users";
import Swal from 'sweetalert2';



const RecoverPss = () => {
  
  const form = [["Correo", "envelope", "email"]];
  const buttons = ["Recuperar","volver al inicio"];
  const history = useHistory();

  const RecoverSubmit = async (user: any, button: string) => {
    ["Recuperar", "Enviar"].includes(button) ? RecoverPssSubmit(user): history.push("/");
  };

  const RecoverPssSubmit = async (e: any) => {
    const valid = () => {
      history.push("/");
      Swal.fire({
        text: "Espera en tu correo un link para recuperar tu cuenta",
        showConfirmButton: false,
        timer: 3000,
        icon: 'success',
      });
    };

    

    const req = await recover(e.Correo);
    req.status ? valid() : Swal.fire({
      text: "Por Favor Dijite su Correo Electronico",
      icon: "warning",
      timer: 3000,
      showConfirmButton: false,
    });
  };

  return (
    <>
      <CentralBox
        title="Recupera tu cuenta"
        phrase="Ingresa tu correo para crear una nueva contraseña"
        height="55%"
        buttons={buttons}
        infoForm={form}
        onSubmit={RecoverSubmit}
      />
    </>
  );
};

export default RecoverPss;
