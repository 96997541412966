import React from "react";
import styled from "styled-components";

type Props = {
  ocr: string;
};

const OCR = ({ ocr }: Props) => {
  const lineBreakText = (ocr: string) => {
    return ocr.split("\n").map((line) => <p>{line}</p>);
  };
  return <Text>{lineBreakText(ocr)}</Text>;
};

export default OCR;

const Text = styled.div`
  /* overflow-y: scroll; */
  margin-top: 2rem;

  p {
    margin-top: 0.5rem;
    font-size: 1rem;
    text-align: center;
    padding-left: 3rem;
  }
`;
