import React from 'react'
import styled from 'styled-components'


const Waiting=()=> {
    return (

    <Circles>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
            
    </Circles>
    )
}

export default Waiting

const Circles=styled.div`

    margin: 3rem;
    display: flex;
    border-radius: 0.75rem;
    padding: 0 7rem 0 0;

 span {
    width: 2rem;
    height: 2rem;
    background: #b7c1de;
    border-radius: 50%;
    margin-left: 1rem;
  }
  
span:nth-child(2) {
    background: #63345e;
  }
  
span:nth-child(3) {
    background: #0b468c;
  }
  
span:nth-child(4) {
    background: #ac61b9;
  }
  
span:not(:last-child) {
    animation: animate 1.25s linear infinite;
  }
  
  @keyframes animate {
    0% {
      transform: translateX(0);
    }
  
    100% {
      transform: translateX(10rem);
    }
  }
  

  span:last-child {
    animation: jump 1.25s ease-in-out infinite;
  }
  
  @keyframes jump {
    0% {
      transform: translate(0, 0);
    }
    10% {
      transform: translate(1rem, -1rem);
    }
    20% {
      transform: translate(1.5rem, -2.5rem);
    }
    30% {
      transform: translate(2rem, -3rem);
    }
    70% {
      transform: translate(-8.5rem, -3rem);
    }
    80% {
      transform: translate(-8.25rem, 1rem);
    }
    90% {
      transform: translate(-8rem, -0.5rem);
    }
    100% {
      transform: translate(-6rem, 0);
    }
  }
   


`
