import styled from 'styled-components'
import {blue,pink} from '../../App.styles'


export const Upload = styled.div`
    margin-top: 1rem;
    padding: 3rem;
    padding-top: 3rem;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 55%;
    height: 75%;
    background-color: white;
    box-shadow: 0 0 7px 7px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction:column;
    align-items: center;

    #file{
        display: none;
    }

    h1{
        
        color:${blue};
        margin-bottom: 1rem;
        font-size: 2rem;
    }

    .subtitle{
        font-size: 1.25rem;
        color: ${pink};
        margin-bottom: 2rem;
        font-size: 1.5rem;
        text-align: center;
    }

    .cargar-imagen-label{
        padding:0.75rem 5rem; 
        font-size: 1.25rem;
        margin-bottom: 1rem;
        cursor: pointer;
        border: none;
        border-radius: 0.75rem;
        background-color:${blue};
        position: relative;
        color: white;
    }

    .imgBot{
        position: absolute;
        bottom:0;
        right:0;
        cursor: pointer;
    }

    .back-to-main-menu-link {
        display: block;
        text-align: center;
        margin-top: 2.5rem;
        font-size: 1.4rem;
        color: ${pink};
        text-decoration: none;
        cursor: pointer;
      }
      
    .dropzone{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        border: 4px dashed #b7b0b0;
        padding: 20px;
        position: absolute;
        height: 54%;
        left: 21%;
        top: 123px;
    }

    .active{
        right: 220px;
        width: 46%;
        left: 75px;
    }
    
    .contenedorPadre{
        width: 101%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .subtitleDropzone{
        font-size: 20px;
        margin-bottom: 2rem;
        text-align: center;
    }

    .file-item {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        justify-content: space-between;
    }
      
    .file-text {
        margin-right: 20px;
    }

    .Buttons{
        margin-top: 46%;
        position: absolute;
    }

    .cargar-imagen-labelTwo{
        padding:0.75rem 5rem; 
        font-size: 1.25rem;
        cursor: pointer;
        border: none;
        border-radius: 0.75rem;
        background-color:${blue};
        position: relative;
        color: white;
        top: 1.5rem;
    }

    @media (min-width: 1800px) {
        .dropzone{
            left: 29.5%;
        }

        .active{
            top: 123px;
            right: 220px;
            width: 46%;
            left: 75px;
        }
    }

`