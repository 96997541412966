import styled from "styled-components";
import { blue } from "../../App.styles";

export const Foot = styled.div`
  background-color: ${blue};
  padding: 1.25rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .footer-links a {
    margin-right: 1.5rem;
    color: white;
    text-decoration: none;
    padding-right: 2rem;
    font-size: 1rem;
  }

  .disabled-link {
    pointer-events: none;
  }

  .social-icons {
    color: white;
    padding-left: 0.75rem;
    font-size: 2.25rem;
  }
  .copyright {
    color: #bcbbbb;
    font-size: 1rem;
  }
`;
