import styled from "styled-components";
import { Dialog} from '@material-ui/core';


interface ContainerProps {
    isOpen: boolean;
    
  }

  export const Container = styled.div<ContainerProps>`
    color-text:#202020;
    position: sticky;
    padding-top:20px;
    // background: rgba(176,211,229,0.7);
    border-right: 1px gray;

    .Sidebarbutton{
        position: absolute;
        top: 48px;
        right: -20px;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: #1D5881;
        box-shadow: 0 0 4px;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all 0.5s;
        transform: ${({isOpen})=>(isOpen? `initial`:`rotate(180deg)`)};
        letter-spacing: inherit;
        color: inherit;
        font-size: inherit;
        padding:0;
        font-family: inherit;
        outline: none;
        user-select: none;
        margin:10px;
    }

    .Logocontent{
        display:flex;
        justify-content:center;
        align-items: center;
        padding-bottom: 26px;
        
        .img-content{
            display:flex;
            img{
                max-width:100%;
                height:auto
            }
            transition: all 0.5s;
            transform: ${({isOpen})=>(isOpen? `scale(0.9)`:`scale(1.2)`)};
        }
        h2{
            display: ${({isOpen}) => (isOpen? `block`:`none`)};
            color-text:#202020;
        }
    }
    .Linkcontent{
        margin: 8px 0;
        padding: 0 15%;
        :hover{
            background:#eeeeee;

        }
        .Linkstext{
            display:flex;
            align-items: center;
            text-decoration:none;
            color: #202020;
            padding: 8px 0;
            white-space: nowrap; 
            overflow: hidden; 
            text-overflow: ellipsis;

            .Linkicon{
                padding: 8px 16px;
                display:flex;
                color: #B83674;
                svg{
                    font-size:30px;
                }
            }
        }
    }
    .Logocontent{
        display:flex;
        justify-content:center;
        align-items: center;
        padding-bottom: 26px;
        
        .img-content{
            display:flex;
            img{
                max-width:100%;
                height:auto
            }
            transition: all 0.5s;
            transform: ${({isOpen})=>(isOpen? `scale(0.9)`:`scale(1.2)`)};
        }
        h2{
            display: ${({isOpen}) => (isOpen? `block`:`none`)};
            color-text:#202020;
        }
    }
    .Linkcontent2{
        margin: 8px 0;
        padding: 0 15%;
        :hover{
            background:#eeeeee;

        }
        .Linkstext2{
            display:flex;
            align-items: center;
            text-decoration:none;
            color: #202020;
            padding: 8px -2px 0;

            .Linkicon2{
                padding: 8px 16px;
                display:flex;
                color: #1D5881;
                svg{
                    font-size:30px;
                }
            }
        }
`;

export const StyledDialog = styled(Dialog)`
    &.MuiDialog-root {
        .MuiDialog-paper {
            width: 360px;
            height: 220px;
            border-radius: 15px;
            background-color: #f5f5f5;


            .SecondaryCard {
                position: absolute;
                top: 15px;
                left: 10px;
                width: 340px;
                height: 150px;
                background-color: grey;
                border-radius: 10px;
                border-bottom-left-radius: 0; 
                border-bottom-right-radius: 0;
            }

            .changePasswordButton {
                display: flex;
                gap: 5px; 
                background-color: #007BFF;
                color: white; 
                border: none; 
                padding: 10px; 
                border-radius: 5px; 
                cursor: pointer;    
                margin-left: 150px;
            }
            .buttonContainer {
                display: flex;
                justify-content: center;
                .changePasswordButton {
                    display: flex;
                    align-items: center;
                    gap: 5px; 
                    background-color: #007BFF;
                    color: white; 
                    border: none; 
                    padding: 10px; 
                    border-radius: 5px; 
                    cursor: pointer;
                    transition: background-color 0.3s ease;
                    outline: none;
                    &:hover,
                    &:active,
                    &:focus {
                        background-color: #B83674;
                        outline: none;
                    }
                    .iconchangepassword {
                        font-size: 15px; 
                    }
                }     
            }    
        }
    }

    .MuiDialogTitle-root {
        display: flex;
        flex-direction: row; 
        justify-content: flex-start; 
        align-items: center;
        padding: 0 20px;  
    }
    
    .myIconFeedback {
        width: 90px; 
        height: 90px; 
        margin-right: 20px;
    }

    .nameContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 10px;
        margin-left: 15px;
    
        .userTitle {
            color: #fff; 
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 0;
        }
    
        .email {
            color: #fff; 
            font-size: 10px;
            font-weight: normal;
            margin-top: 0;
        }    
    }
    
    .CeClose {
        margin-bottom: 10px;
    }
`;

export const Divider = styled.div<ContainerProps>`
    height: 3px;
    width: 100%;
    background: #eeeeee;
`;

