import styled from "styled-components";
import { pink, blue, light_blue } from "../../App.styles";

export const Box = styled.div<{ height: string }>`
  margin-top: 1rem;
  padding: 3rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: ${(props) => props.height};
  background-color: white;
  box-shadow: 0 0 7px 7px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  max-width: 600px;
  word-wrap: break-word;
  max-font-size: 18px;
  
  @media (min-width: 768px) { 
    width: 45%; 
  }
  
  button{
    width: 20rem;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  h1 {
    color: ${blue};
    margin-bottom: 1rem;
    font-size: 2rem;
  }
  .phrase {
    font-size: 1.25rem;
    color: ${pink};
    margin-bottom: 2rem;
    font-size: 1.5rem;
    text-align: center;
  }

  a {
    margin-bottom: 1.25rem;
    font-size: 0.8rem;
    color: ${blue};
  }
`;

export const Inputs = styled.div`
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 2rem;
  position: relative;
  width: 100%;

  @media (min-width: 768px) {
    width: 75%;

  input {
    padding-bottom: 1rem;
    padding-right: 1rem;
    border: none;
    cursor: pointer;
    font-size: 1.25rem;
    outline: none;
    background-color: white;
    width: 100%;
    width: auto;
  }
}

  input::-webkit-calendar-picker-indicator {
    opacity: 0;
  }

  input:-webkit-autofill {
    transition: background-color 5000s ease-in-out 0s;
  }

  .font {
    margin-right: 1rem;
    font-size: 1.5rem;
    color: ${blue};
  }

  .passwordFont,
  .notpasswordFont {
    position: absolute;
    right: 1rem;
    font-size: 1.25rem;
    color: ${light_blue};
    cursor: pointer;
  }

  .email-input {
    position: relative;
  }
  
  .error {
    position: absolute;
    top: 120%;
    left: 0;
    font-size: 1.1rem;
    color: red;
  }


  
`;

export const RadioContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  
  p {
    margin-right: 1rem;
    cursor: pointer;
    font-size: 1.25rem;
  }

  label {
    margin-right: 1rem;
    cursor: pointer;
    font-size: 1.25rem;
  }

  input[type="radio"] {
    margin-right: 5px;
  }
`;

