import React,{ChangeEvent,useEffect,useState} from "react";
import { Table } from "./Processescomplete.style";
import { recoveryRPAjson } from "../../requests/Data";
import  saveAs from 'file-saver';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import Tooltip from '@material-ui/core/Tooltip';
import { BsFiletypeXlsx, BsFiletypeCsv, BsFiletypeJson} from "react-icons/bs";


type Props = {
  list: string[];
  onEdit: (id: string) => void;
  IsCheckedZip: (e: ChangeEvent<HTMLInputElement>, id: string) => void;
  dList: string[];
  isSuperUser: boolean; 
};

function formatearFecha(fecha: Date): string  {
  const opciones: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  };
  return new Date(fecha).toLocaleString('es-ES', opciones).replace(/\sde\s/g, '/');
}

const Processescomplete = ({ list, onEdit, IsCheckedZip, dList, isSuperUser}: Props) => {


  const [selectedItemsCount, setSelectedItemsCount] = useState(0);

  const downloadDataJson = async (ui_request:string) =>{
    try {
      const responseData = await recoveryRPAjson(ui_request);
      const blob = new Blob([JSON.stringify(responseData)], {type:'application/json'});
      saveAs(blob, `${ui_request}.json`);
    } catch (error) {
      console.error("Error downloading data: ", error);
    }
  }

function flattenObject(obj: any) {
    const flattened: any = {};

    Object.keys(obj).forEach((key) => {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
            const innerObj = flattenObject(obj[key]);
            Object.keys(innerObj).forEach((innerKey) => {
              flattened[`${key}_${innerKey}`] = innerObj[innerKey];
          });

      } else {
        flattened[key] = obj[key];
      }
  });
  return flattened;
}

const downloadDataCsv = async(ui_request:string) => {
    try {
        const response = await recoveryRPAjson(ui_request);

        let responseData;
        if (typeof response === 'string') {
            responseData = JSON.parse(response);
        } else {
            responseData = response;
        }
        const flattenedData = flattenObject(responseData); 
        const csvData = Papa.unparse([flattenedData], {quotes: true});
        const blob = new Blob(['\ufeff' + csvData], {type: 'text/csv;charset=utf-8;'});
        saveAs(blob, `${ui_request}.csv`);

    } catch (error) {
        console.error("Error downloading data: ", error);
    }
   
}


const downloadDataXlsx = async (ui_request:string) => {
  try {
    const responseData = await recoveryRPAjson(ui_request);
    const flattenedData = flattenObject(responseData);

    var wb = XLSX.utils.book_new();
    var ws = XLSX.utils.json_to_sheet([flattenedData]); 
    XLSX.utils.book_append_sheet(wb, ws, "ResponseData");
    XLSX.writeFile(wb, `${ui_request}.xlsx`);

  } catch (error) {
    console.error("Error downloading data: ", error);
  }
};

  useEffect(() => {
    setSelectedItemsCount(dList.length);
  }, [dList]);

  const TableHeaderRow = (
    
    <tbody>
      <tr>
        <th />
        <th>ID</th>
        <th>ESTADO</th>
        <th>ÚLTIMA EJECUCIÓN</th>
        <th>ACCIONES  </th>
      </tr>
    </tbody>
    
  );
  const TableRows = list.map((data:any, index: number) => (

      <tr className={data.reintentos >= 3 ? "backoffice" : ""} key={index}>
        <td>
          <input
            type="checkbox"
            checked={dList.includes(data.ui_request) ? true : false}
            onChange={(e) => IsCheckedZip(e, data.ui_request)}
          />
        </td>
        <td>
          <a
            href="/Completlist/:"
            className="id"
            onClick={(e) => {
              onEdit(data.ui_request);
              e.preventDefault();
            }}
          >
            {data.ui_request}
          </a>
        </td>
        <td>{data.process_status}</td>
        <td>{formatearFecha(data.created_at)}</td>
        <td>
          
    {selectedItemsCount > 1 ? (
      <div></div>
) : (
    <div>
      <Tooltip title="JSON">
        <a
          aria-label="JSON"
          href="/:"
          onClick={(e) => {
            e.preventDefault();
            downloadDataJson(data.ui_request);
          }}
        >
          <BsFiletypeJson className="JsonDocument" />
        </a>
      </Tooltip>

      <Tooltip title="CSV">
        <a
          aria-label="CSV"
          href="/:"
          onClick={(e) => {
            e.preventDefault();
            downloadDataCsv(data.ui_request);
          }}
        >
          <BsFiletypeCsv className="CsvDocument" />
        </a>
      </Tooltip>
        
      <Tooltip title="XLSX">
        <a
          aria-label="XLSX"
          href="/:"
          onClick={(e) => {
            e.preventDefault();
            downloadDataXlsx(data.ui_request);
          }}
        >
          <BsFiletypeXlsx className="XlsxDocument" />
          </a>
        </Tooltip>
      </div>
    )}    
    </td>
  </tr>
));
  

  return (
    <Table>
      {TableHeaderRow}
      <tbody >
      {TableRows}
      </tbody>
    </Table>
  );
};

export default Processescomplete;
