import React,{useState,useMemo} from 'react';
import {BrowserRouter as Router, Switch,Route} from 'react-router-dom'
import Navbar from './components/layout/Navbar';
import { GlobalStyle,Container,Wrapper } from './App.styles';
import Footer from './components/layout/Footer';
import Assistant from './pages/assistant/Assistant';
import List from './pages/list/List';
import Client from './pages/client/Client';
import Login from './pages/crud/Login'
import Register from './pages/crud/Register'
import RecoverPss from './pages/crud/RecoverPss';
import VerifyUser from './pages/crud/VerifyUser';
import ChangePss from './pages/crud/ChangePss';
import ZC from './pages/zat capture/Zc';
import { authToken } from './context/Context';

import NotFound from './pages/not found/NotFound';
import Listcomplet from './pages/list/Listcomplete';
import CustomAssistant from './pages/assistant/CustomAssistant';
const App = () => {

  const [token,setToken]=useState(localStorage.getItem('Token'))
  const providerValue = useMemo(()=>({token,setToken}),[token,setToken])

  return (
    <>
    <GlobalStyle />
    <Container>
      <Router>

      <Wrapper>
      <authToken.Provider value={providerValue}>
        <Navbar/>
        <Switch>
        <Route exact  path='/' component ={Login}/>
        <Route exact  path='/Register' component ={Register}/>
        <Route exact  path='/Password' component ={RecoverPss}/>
        <Route exact  path='/recuperar-contrasena' component ={ChangePss}/>
        <Route exact  path='/error' component ={NotFound}/>
        <Route exact  path='/Verify' component ={VerifyUser}/>
        {!token&&<NotFound/>}
        <Route exact  path='/Client' component ={Client}/>
        <Route path='/List' component ={List}/>
        <Route path='/ZC' component ={ZC}/>
        <Route path='/Completados' component ={Listcomplet}/>
        <Route path='/Editlist/:List' component ={Assistant}/>
        <Route path='/Completlist/:Completados' component ={CustomAssistant}/>
       </Switch>
        </authToken.Provider>
        </Wrapper>
        <Footer/>
      </Router>
      </Container>

    </>
  );
}




export default App;

