import React, { useState, useEffect } from "react";
import { StudentInfo } from "../../types/Student";
import OCR from "../documents/OCR";
import { Form, Docs } from "./Editing.styles";
import Imgs from "../documents/Imgs";
import Button from "../layout/Button";
import { blue, light_blue, pink } from "../../App.styles";
import { TextField } from "@material-ui/core";
import Reclasification from "./Reclasification";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faSearchPlus, faSearchMinus, faBook, faCheck } from "@fortawesome/free-solid-svg-icons";
import { ReprocessImages, toZC } from '../../requests/ZC';
import { jsPDF } from 'jspdf';


type Props = {
  values: StudentInfo;
  onChange: (key: string, value: string) => void;
  ChangeTipo: (r: any) => void;
  view: boolean;
};

const Editing = ({ values, onChange, ChangeTipo, view }: Props) => {
  const ocr = values["TEXTO_EXTRAIDO"];
  const image = values["NOMBRE_IMAGEN"];
  const [zoom, setZoom] = useState(1);

  const inputs = Object.keys(values).filter(
    (val) =>
      !["TEXTO_EXTRAIDO", "NOMBRE_IMAGEN", "ID_NODO_RELACIONADO"].includes(val)
  );
  const disable = ["TIPO", "NOMBRE_ARCHIVO"];

  const handleChange = (e: any) => {
    const event = e.target;
    const key = event.parentElement.previousElementSibling.innerText.trim();
    const value = event.value;
    onChange(key, value);
  };
  const handlechangeTiporesult = async (tipo: any, valueNew: any) => {
    const NEW_TIPO = valueNew.TIPO ? valueNew.TIPO : tipo;
    const r = [
      (({
        NOMBRE_IMAGEN,
        NOMBRE_ARCHIVO,
        ID_NODO_RELACIONADO,
        TEXTO_EXTRAIDO,
      }) => ({
        NOMBRE_IMAGEN,
        NEW_TIPO,
        NOMBRE_ARCHIVO,
        ID_NODO_RELACIONADO,
        TEXTO_EXTRAIDO,
      }))(valueNew),
    ];
    ChangeTipo(r);
    setChangeClass(!changeClass);
  }

  const handleChangeTipo = async (e: any) => {
    const NEW_TIPO = e.target.innerText;
    const r = [
      (({
        NOMBRE_IMAGEN,
        NOMBRE_ARCHIVO,
        ID_NODO_RELACIONADO,
        TEXTO_EXTRAIDO,
      }) => ({
        NOMBRE_IMAGEN,
        NEW_TIPO,
        NOMBRE_ARCHIVO,
        ID_NODO_RELACIONADO,
        TEXTO_EXTRAIDO,
      }))(values),
    ];
    ChangeTipo(r);
    setChangeClass(!changeClass);
  };

  const createForms = inputs.map((label, index) => (
    <TextField
      label={label}
      value={values[label] ? values[label] : " "}
      onChange={handleChange}
      InputProps={{ disabled: disable.includes(label) ? true : false }}
      className={values[label]==='' ?  "form emptyform" : "form" }
      size="small"
    />
  ));

  const [rotate, setRotate] = useState(0);
  const [loading, setLoading] = useState(false);
  const [imageHasLoaded, setImageHasLoaded] = useState(false);

  

  useEffect(() => {
    setChangeClass(true);
    SetIsImage(true);
    setRotate(0);
  }, [view]);


  const [isImage, SetIsImage] = useState(true);

  const change = () => {
    SetIsImage(!isImage);
  };

  const [showButton, setShowButton] = useState(false);

  const rotateImage = () => {
    setRotate(rotate + 90);
    setShowButton(true);
  };

  const Imageloaded = () => {
    setImageHasLoaded(true);
  }

  useEffect(() => {
    setShowButton(false); 
  }, [image]);

  const zoomIn = () => {
    if (zoom < 2.5) { 
      setZoom(zoom + 0.2);
    }
  };
  
  const zoomOut = () => {
    if(zoom > 0.7) { 
      setZoom(zoom - 0.1); 
    }
  };

  const getRotatedCanvasSize = (width:number, height:number, rotationAngle:number) => {
  const radians = (rotationAngle * Math.PI) / 180;
  const sin = Math.sin(radians);
  const cos = Math.cos(radians);
  return {
    width: Math.abs(width * cos) + Math.abs(height * sin),
    height: Math.abs(width * sin) + Math.abs(height * cos),
  };
};


  const customer = window.__RUNTIME_CONFIG__.CUSTOMER;
  const process = window.__RUNTIME_CONFIG__.PROCESS;

  const reprocessImage = async (image: string, rotate:number) => {
    const idNodoRelacionado = values["ID_NODO_RELACIONADO"];
    try {
        const img = new Image();
        img.crossOrigin = 'anonymous';
        
        img.onerror = (error) => {
            throw new Error("Error loading the image");
        };

        img.src = image;
        await new Promise((resolve, reject) => {
            img.onload = resolve;
            img.onerror = reject;
        });

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) {
            throw new Error('Could not create 2D context');
        }

        const rotationAngle = rotate; 

        const { width: newWidth, height: newHeight } = getRotatedCanvasSize(img.width, img.height, rotationAngle);

        canvas.width = newWidth;
        canvas.height = newHeight;
        
        ctx.translate(newWidth / 2, newHeight / 2);
        ctx.rotate((rotationAngle * Math.PI) / 180);
        ctx.drawImage(img, -img.width / 2, -img.height / 2, img.width, img.height);

        const imageBlob = await new Promise((resolve) => canvas.toBlob(resolve, 'image/jpeg', 1));


        const doc = new jsPDF('p', 'mm', [newWidth, newHeight]);
        const imageUrl2 = URL.createObjectURL(imageBlob as Blob);
        doc.addImage(imageUrl2, 'JPEG', 0, 0, newWidth, newHeight);
        const pdfBytes = doc.output('arraybuffer');
        const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });

        const imageFile = new File([pdfBlob], "Documento_reprocesado.pdf", { type: "application/pdf" });

        const extractProcessIdFromImageUrl = (url:any) => {
          const match = url.match(/\/([^\/]+)-[^\/]+\.png/);
          if (match && match[1]) {
              return match[1];
          } else {
              console.warn("ID no encontrado en la URL");
              return null;
          }
      }      

        const formData = new FormData();
        formData.append("customer", customer);
        formData.append("process", process);
        formData.append("file", imageFile);
        const processId = extractProcessIdFromImageUrl(image);
          if (processId) {formData.append("id_process_retry", processId);}
        formData.append("verbose", String(true));
        formData.append("standard_output", String(true));
        formData.append("id_node_retry", idNodoRelacionado);
        const res = await ReprocessImages(formData);

        const data = res.data;
        const propertyNames = Object.keys(data);
        const firstProperty = propertyNames[0];
        const textProperty = propertyNames[2];

        const values = data[firstProperty];

        values['TEXTO_EXTRAIDO'] = data[textProperty];
        values['NOMBRE_IMAGEN'] = image;
        values['ID_NODO_RELACIONADO'] = idNodoRelacionado;


        handleClick();
        settipoValue(data[firstProperty].TIPO);
        setValueNews(values);
        setShowButton(false);

    } catch (error: any) {
      if (error.response) {
        console.error("Error en la respuesta:", error.response.data);
    } else if (error.request) {
        console.error("No hubo respuesta:", error.request);
    } else {
        console.error("Otro error:", error.message);
    }
    }
};


  const setDocs = (buttonText: string, fontIcon: any, doc: JSX.Element, thirdButton?: JSX.Element) => {
    return (
      <Docs>
        <div>
          <Button
            bcolor={blue}
            icon={fontIcon}
            text={buttonText}
            onClick={change}
          />
          {isImage && (
            <>
            <Button
            bcolor={pink}
            icon="redo-alt"
            onClick={(rotateImage)}
            text="Rotar"
            disabled={loading || !imageHasLoaded}
            
          />
          <FontAwesomeIcon
            icon={faSearchPlus}
            onClick={zoomIn}
            className="zoom-icon"
          />
          <FontAwesomeIcon
            icon={faSearchMinus}
            onClick={zoomOut}
            className="zoom-icon"
          />
          </>
          )}
          {thirdButton}
        </div>
        {doc}
      </Docs>
    );
  };
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard =()=> {
    navigator.clipboard.writeText(ocr).then(function () {
      setIsCopied(true);
      setTimeout(()=>{
        setIsCopied(false);
      },3000);
    }, function(err){
      console.error('Async: Could not copy text: ', err);
    });
  };


  const copyButton =(
      <Button
      bcolor={pink}
      color="white"  
      icon={isCopied? faCheck : faBook}  
      text={isCopied ? "Texto copiado" : "Copiar texto"}
      onClick={copyToClipboard}
    />
  );

  const [valueNews, setValueNews] = useState<Record<string, any>>({});
  const [changeClass, setChangeClass] = useState(true);
  const [tipoValue,settipoValue] = useState("");
  const changeButton = changeClass
    ? [
        "Cambiar Tipo",
        "exchange-alt",
        "Información a Editar",
        "Al editar la información, sobreescribirá los datos capturados",
      ]
    : [
        "Editar",
        "edit",
        "Cambiar Tipo Documento",
        `Actual:  ${values["TIPO"]}`,
      ];

  const handleClick = () => {
    setChangeClass(!changeClass);
  };

  useEffect(() => {
    if (tipoValue && valueNews.TIPO){
      handlechangeTiporesult(tipoValue, valueNews);
      settipoValue("");
      setValueNews({});
    }
  }, [tipoValue, valueNews]);

  return (
    <>
      <Form>
        <h2>{changeButton[2]}</h2>
        <p>{changeButton[3]}</p>

        <div className="inputtext">
          {changeClass ? (
            <div className="editForm">{createForms}</div>
          ) : (
            <Reclasification onClick={handleChangeTipo} />
          )}
        </div>
        <div className="formbutton">
          <Button
            bcolor={light_blue}
            text={changeButton[0]}
            icon={changeButton[1] as IconProp}
            onClick={handleClick}
          />
        </div>
      </Form>

      {isImage
        ? setDocs(
            "Vista Texto",
            "file-alt",
            <Imgs key={image} rotate={rotate} image={image} zoom={zoom} showButton={showButton} reprocessImage={reprocessImage} onImageLoaded={Imageloaded}/>
          )
          
        : setDocs("Vista Imagen", "image", <OCR ocr={ocr} />, copyButton)}
        
    </>
  );
};

export default Editing;
