import styled from "styled-components";
import { blue, pink } from "../../App.styles";

export const Table = styled.table`
  margin-top: 2rem;
  text-align: center;
  border-collapse: collapse;
  border-radius: 0.75rem;
  border-style: hidden;
  box-shadow: 0 0 0 2px rgba(205, 227, 238, 0.5);

  th:first-child {
    border-radius: 0.75rem 0 0 0;
  }

  th:last-child {
    border-radius: 0 0.75rem 0 0;
  }

  tr:last-child td:first-child {
    border-radius: 0 0 0 0.75rem;
  }

  tr:last-child td:last-child {
    border-radius: 0 0 0.75rem 0;
  }

  th {
    font-size: 1rem;
    color: ${blue};
    padding: 0.5rem 2rem;
    background-color: rgba(176, 211, 229, 0.7);
  }
   tr:nth-child(odd) {
    background-color: rgba(205, 227, 238, 0.3);
  }
  .backoffice {
    background-color: rgba(255, 87, 51, 0.3) !important;
  }
  td {
    padding: 1.5rem 1rem;
    font-weight: bold;
    color: ${blue};
  }

  .id {
    color: ${pink};
    text-decoration: none;
  }

  .Edit-icon{
    font-size: 15px;
    filter: grayscale(70%)
    position: relative; 
    left: -60px;
  }

  .Trash-icon {
    margin-left: 15px; 
    font-size: 15px;
    filter: grayscale(100%)
  }

  .Download-icon {
    margin-left: 15px; 
    font-size: 15px;
    color: ${pink};
  }
`;