import React,{ChangeEvent,useEffect,useState} from "react";
import { Table } from "./Processes.styles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@material-ui/core/Tooltip';


type Props = {
  list: string[];
  onEdit: (id: string) => void;
  IsChecked: (e: ChangeEvent<HTMLInputElement>, id: string) => void;
  dList: string[];
  lisDelete: (id: string) => void; 
  oneDelete: (id: string) => void;
  isSuperUser: boolean; 
};

function formatearFecha(fecha: Date): string  {
  const opciones: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  };
  return new Date(fecha).toLocaleString('es-ES', opciones).replace(/\sde\s/g, '/');
}

const Processes = ({ list, onEdit, IsChecked, dList, lisDelete, oneDelete, isSuperUser}: Props) => {

  const [selectedItemsCount, setSelectedItemsCount] = useState(0);
  

  useEffect(() => {
    setSelectedItemsCount(dList.length);
  }, [dList]);

  const TableHeaderRow = (
    
    <tbody>
      <tr>
        <th />
        <th>ID</th>
        <th>REINTENTOS</th>
        <th>ESTADO</th>
        <th>ÚLTIMA EJECUCIÓN</th>
        <th>ACCIONES  </th>
      </tr>
    </tbody>
    
  );
  const TableRows = list.map((data:any, index: number) => (
    
    
   
      <tr className={data.reintentos >= 3 ? "backoffice" : ""} key={index}>
        <td>
          <input
            type="checkbox"
            checked={dList.includes(data.ui_request) ? true : false}
            onChange={(e) => IsChecked(e, data.ui_request)}
          />
        </td>
        <td>
          <a
            href="/Editlist/:"
            className="id"
            onClick={(e) => {
              onEdit(data.ui_request);
              e.preventDefault();
            }}
          >
            {data.ui_request}
          </a>
        </td>
        <td>{data.reintentos}</td>
        <td>{data.process_status}</td>
        <td>{formatearFecha(data.created_at)}</td>
        <td>
      <Tooltip title="Editar">
        <a
          aria-label="Editar"
            href="/Editlist/:"
            onClick={(e) => {
              onEdit(data.ui_request);
              e.preventDefault();
            }}
          >
            <FontAwesomeIcon icon={faEdit} className="Edit-icon" />
        </a>
      </Tooltip>
      {isSuperUser === true ? (
      <>
        {selectedItemsCount <= 1 &&
           <Tooltip title="Borrar">
            <a
              aria-label="Borrar"
              href="/Editlist/:"
              onClick={(e) => {
                oneDelete(data.ui_request);
                e.preventDefault();
              }}
            >
              <FontAwesomeIcon icon={faTrash} className="Trash-icon" />
            </a>
            </Tooltip>
          }
          </>
          ) : (
            <React.Fragment>{""}</React.Fragment>
          )}
        </td>
      </tr>
  ));
  

  return (
    <Table>
      {TableHeaderRow}
      <tbody >
      {TableRows}
      </tbody>
    </Table>
  );
};

export default Processes;
