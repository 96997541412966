import React, { useState, useEffect } from "react";
import { Sidebar } from "../list/sidebarMenu/sidebarMenu";
import { Container, Wrapper } from "./List.styles";
import { useLocation } from "react-router-dom";
import {
  pendings,
  getInfoStudent,
  classList,
  removeList,
  processes,
  userInfo,
  search_pending
} from "../../requests/Data";

import Processes from "../../components/processes/Processes";
import { useHistory } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTrash ,faTimes } from "@fortawesome/free-solid-svg-icons";
import Tooltip from '@material-ui/core/Tooltip';
import Swal from 'sweetalert2';
import axios from "axios";

const Listado = () => {
  const [list, setList] = useState([]);
  const [classes, setClasses] = useState([]);

  const location = useLocation();
  const history = useHistory();

  const [offset, setOffset] = useState(0);

  const [deleteProcess, setDeleteProcess] = useState<string[]>([]);
  const [searchId, setSearchId] = useState("");

  const [showClearIcon, setShowClearIcon] = useState(false);
  const [isOpen, setisOpen] = useState(false);
  const [superUser, setSuperUser] = useState(Boolean);
  const [email, setEmail] = useState<string | null>(null); 

  const [searchPending, setSearchPending] = useState<any[]>([]);
  const [hasSearched, setHasSearched] = useState(false);
  const [isSearchCleared, setIsSearchCleared] = useState(false);

  const GetPendingProcess = async () => {
    setDeleteProcess([]);
    const clientProcess = location.state;
    const process5 = { ...clientProcess, ...{ offset: offset, limit: 6 } };

    const [processList, classesList] = await axios.all([
      pendings(process5),
      classList(clientProcess.customer),
    ]);

    setList(await processList.data);
    setClasses(await classesList.data);
    const currentPage = location.state?.currentPage || 1;
    const currentPageElement = document.querySelector(`.tables:nth-child(${currentPage})`);
    if (currentPageElement) {
      onClick({ target: currentPageElement });
    }
  };

  useEffect(() => {
    GetPendingProcess();
  }, [offset]);

  const handleClick = async (id: string) => {
    //api a modificar para q traiga la  nueva propiedad
    const req = await getInfoStudent(id);
    const data=await req.data;
    const studenInfo = data.related_json_node;
    const process_status_detail=data.process_status_detail;
    req.status === 200
      ? history.push(`/Editlist/${id}`, {
          json: studenInfo,
          ui_request: id,
          classes: classes,
          process_status_detail:process_status_detail
        })
      : alert(`Estudiante ${id} esta siendo procesado por otro usuario`);
  };

  const onClick = (e: any) => {
    const value = e.target;
    setOffset((value.innerText - 1) * 6);
    localStorage.setItem('paginaActual', value.innerText);
    document
      .getElementsByClassName("selectedTable")[0]
      .classList.remove("selectedTable");
    value.classList.add("selectedTable");
    history.replace(location.pathname, { ...location.state, currentPage: value.innerText });
  };

  const numberOfRecords = parseInt(list[1]); 
  const recordsPerPage = 6;
  const numberOfPages = Math.floor(numberOfRecords / recordsPerPage) + (numberOfRecords % recordsPerPage !== 0 ? 1 : 0);
  
  const tables = Array.from(
    { length: numberOfPages }, 
    (object, index) => (
      <p
        key={index} 
        className={index !== 0 ? "tables" : "selectedTable tables"}
        onClick={onClick}
      >
        {index + 1}
      </p>
    )
  );

const handleSearch = async () => {
  const search = searchId.trim();

  if (search === "") {
    Swal.fire({
      text: `Por favor, ingresa el Id del documento`,
      icon: "question",
      timer: 3000,
      showConfirmButton: false,
    });
    return;
  }

  if (search.length < 3) {
    Swal.fire({
      text: `Por favor, ingresa al menos 3 caracteres para buscar el documento`,
      icon: "question",
      timer: 3000,
      showConfirmButton: false,
    });
    return;
  }

  const clientProcess = location.state;
  const process5 = { ...clientProcess,...{limit: 6}, search: search};

  const searchAll = await search_pending(process5);

  setSearchPending(searchAll.data);
  setHasSearched(true); 
  setIsSearchCleared(false);
}

  useEffect(() => {
    if (hasSearched && !isSearchCleared && searchId.trim() !== "") {
      if (searchPending && Object.values(searchPending)[1] > 0) {
        Swal.fire({
          text: `Se encontro el ID que estaba buscando`,
          icon: "success",
          timer: 3000,
          showConfirmButton: false,
        });
        setShowClearIcon(true);
      } else if (searchPending) {
        Swal.fire({
          text: `No se encontro el ID que estaba buscando`,
          icon: "warning",
          timer: 3000,
          showConfirmButton: false,
        });
        setShowClearIcon(true);
      }
    }
  }, [searchPending, hasSearched, isSearchCleared, searchId]);


  useEffect(() => {
    if (searchId.trim() === "") {
      setSearchPending([]);
    
    }
  }, [searchId]);
  
  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  function limpiarBuscador() {
    setSearchId('');
    setIsSearchCleared(true);
    setShowClearIcon(false);
  }

  const handleClear = () => {
    setSearchId("");
    setShowClearIcon(false);
    setIsSearchCleared(true);
  };

  const handleFormSubmit = (e:any) => {
    e.preventDefault();
    handleSearch();
  };
  const allow_zc = window.__RUNTIME_CONFIG__.ALLOW_CAPTURE;
  const allow_delete = window.__RUNTIME_CONFIG__.ALLOW_DELETE;

  const handleDelete = () => {
    Swal.fire({
      title: '¿Estás seguro que deseas eliminar?',
      showCancelButton: true,
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar',
      icon: 'question',
      width: 500,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await removeList(deleteProcess);
        GetPendingProcess();
        const isFiltered = searchPending.some((item) => deleteProcess.includes(item.ui_request));
        if (isFiltered) {
          setSearchPending([]);
        }
  
        limpiarBuscador();
  
        Swal.fire({
          title:'Eliminado',
          text:'El documento ha sido eliminado correctamente',
          icon:'success',
          showConfirmButton: false,
          timer: 3000
        });
      } else {
        setDeleteProcess([]);
      }
    });
  };

  const handleDeleteSingle = async (id: string) => {
    Swal.fire({
      title: '¿Estás seguro que deseas eliminar?',
      showCancelButton: true,
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar',
      icon: 'question',
      width: 500,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await removeList([id]);
        GetPendingProcess();
  
        const isFiltered = searchPending.some((item) => id === item.ui_request);
  
       
        if (isFiltered) {
          setSearchPending([]);
        }
  
        limpiarBuscador();
  
        Swal.fire({
          title:'Eliminado',
          text:'El documento ha sido eliminado correctamente',
          icon:'success',
          showConfirmButton: false,
          timer: 3000
        });
      }
    });
  };

  const IsChecked = (e: any, id: string) => {
    if (e.target.checked) {
      setDeleteProcess([...deleteProcess, id]);
    } else {
      const remove = deleteProcess.indexOf(id);
      const new_list = deleteProcess.filter((_: any, i: any) => i !== remove);
      setDeleteProcess(new_list);
    }
  };

  const fetchUserInfo = async () => {
    const response = await userInfo();
    setEmail(response.data.email)
    setSuperUser(response.data.is_superuser)
  }

  useEffect(() => {
      fetchUserInfo();
  }, []);

  return (
    <Container className={isOpen? "sidebarState activate": ""}>
        <Sidebar isOpen={isOpen} setisOpen={setisOpen} propsEmail={email ?? ''}/> 
      <Wrapper>
      

      <h1>Listado de Procesos</h1>

    <div className="relative-position">
     
      {superUser === true ? (
      <>
        {deleteProcess.length > 1 && (
          <Tooltip title="Borrar">
            <FontAwesomeIcon icon={faTrash} className="Trashing-icon" onClick={handleDelete} />
          </Tooltip>
        )}
      </>
      ) : (
        <React.Fragment>{""}</React.Fragment>
      )}

      

    <form onSubmit={handleFormSubmit} className="d-flex justify-content-end mb-3">
    <button
          type="button"
          className="btn-clear"
          onClick={limpiarBuscador}
          title="Borrar búsqueda"
        >
          Borrar búsqueda
    </button>

      <div className="search-input">
          <input
            type="text"
            value={searchId}
            onChange={(e) => setSearchId(e.target.value)}
            placeholder="Buscar por ID"
            onKeyPress={handleKeyPress}
            className="form-control"
          />
          {showClearIcon ? (
            <FontAwesomeIcon
            icon={faTimes}
            className="search-icon"
            onClick={handleClear}
          />
          ):(
            <FontAwesomeIcon
            icon={faSearch}
            className="search-icon"
            onClick={handleSearch}
        />
          )}
      </div>
    </form>
  </div>


        {(Object.values(list)[0] && (Object.values(list)[0] as any[]).length > 0) && (
        <Processes
          list={(searchPending.length > 0 && (Object.values(searchPending)[0] as any[]).length > 0) ? Object.values(searchPending)[0] as any[] : Object.values(list)[0] as any[]}
          onEdit={handleClick}
          lisDelete={handleDelete}
          oneDelete={handleDeleteSingle}
          IsChecked={IsChecked}
          dList={deleteProcess}
          isSuperUser={superUser}
        />
      )}

        <div className="pagination">{tables}</div>
        
      </Wrapper>
      
    </Container>
  );
  
};

export default Listado;
