import styled from "styled-components";
import { pink, blue, light_blue } from "../../App.styles";



export const Container = styled.div`



  margin-bottom: 3rem;
  

   .selectClass {
   margin-bottom: 2rem;
   label,  label.Mui-focused{
    font-family: 'Gotham-Bold';
    font-size: 1rem;
    color:${pink};

  }
  }

  .search{

    margin-bottom: 2rem;

    label.Mui-focused{
    font-size: 1.25rem;
    color:${blue};
    margin-bottom:0;
  }
    .MuiInput-underline:after{
      border-color:${blue}
    }

    input{
      font-size: 1.25rem;
      
    }


  }


`;

export const Classes = styled.div`
  max-height: 35vh;
 overflow-y: scroll;
 margin-right:5rem;

.classes {
    width: 90%;
    display: flex;
    color: black;
    text-decoration: none;
    margin-bottom: 1.5rem;
    padding-bottom: 0.5rem;
    font-size: 0.85rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;

  }
.classes:hover{
  color: ${light_blue};
    font-family: 'Gotham-Bold';
    border-left: 5px solid  ${blue};
    padding-left: 0.5rem;
}
  ::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: ${blue}; 
}



`;
