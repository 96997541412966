import React, { MouseEvent } from "react";
import { blue, pink } from "../../App.styles";
import Button from "./Button";
import { Container } from "./Sidebar.styles";

type Props = {
  title?: string;

  sidebarKeys?: JSX.Element[];
  buttons: string[];
  onSubmit?: (e: MouseEvent<HTMLButtonElement>) => void;
};

const Sidebar = ({ title, sidebarKeys, buttons, onSubmit }: Props) => {
  const sideButtons = buttons.map((object, index) => (
    <Button
      bcolor={index ? " #daedf4" : pink}
      color={index ? blue : "white"}
      text={object}
      onClick={onSubmit}
      key={index}
    />
  ));

  return (
    <Container>
      <h3>{title}</h3>
      {sidebarKeys}
      {sideButtons}
    </Container>
  );
};

export default Sidebar;
