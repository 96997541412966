import styled from "styled-components";
import { blue, pink, light_blue } from "../../App.styles";
import Button from "../../components/layout/Button";

// check styles
export const Form = styled.div`
  display: flex;
  flex-direction: column;
  width: 34vw;

  .editForm {
    overflow-y: scroll;
    height: 50vh;
    margin-bottom: 2rem;
  }

  .emptyform{

    fieldset {
        border: 1.75px solid red !important;
      }

  }

  h2 {
    font-family: "Gotham-Book";
    color: ${blue};
    margin-bottom: 1rem;
    font-size: 1.75rem;
  }

  p {
    color: ${pink};
    font-size: 1.1rem;
    margin-bottom: 3rem;
  }

  .form {
    margin: 0.75rem 3rem 3rem 0;
    /* width: 15rem;  */
    display: flex;
    flex-direction: column;
    label {
      color: black;
    }
    label.Mui-focused {
      color: ${pink};
      font-family: "Gotham-Bold";
    }

    .MuiOutlinedInput-root {
      fieldset {
        border: 1.75px solid ${blue};
      }
    }

    .Mui-focused fieldset {
      border: 2px solid ${light_blue}!important;
    }
  }

  .formbutton button {
    text-align: center;
    width: 17rem;
    padding: 0.5rem;
  }
`;

export const Docs = styled.div`
  width: 35vw;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  border-left: 3px solid ${blue};

  button {
    width: auto;
    padding: 0.75rem 5rem;
    margin-left: 1rem;
  }

  .zoom-icon{
    margin-left: 1.5rem;
    cursor: pointer;
    font-size: 1.7rem;
    user-select: none;
  }
`;
export const FloatingButton = styled(Button)`
  // Remueve las siguientes líneas:
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  border-left: 3px solid ${pink};
  margin-top: 10px;
`;