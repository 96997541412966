import React, { useState, useRef} from "react";
import { Upload } from "./Zc.styles";

import { useHistory } from "react-router-dom";
import { toZC, toZA } from "../../requests/ZC";
import Waiting from "./Waiting";
import Swal from 'sweetalert2';

import Dropzone from 'react-dropzone';
import { DropzoneRef } from 'react-dropzone';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from '@fortawesome/free-solid-svg-icons';
  

const ZC = () => {

  const [filesInfo, setFilesInfo] = useState<{ name: string; file: File }[]>([]);

  const handleDrop = (acceptedFiles: File[]) => {
    acceptedFiles.forEach(file =>{
      setFilesInfo(prevState => [
        ...prevState,
        {
          name: file.name,
          file: file
        }
      ])
    })
  }

  const handleDeleteFile = (fileName: string) => {
    setFilesInfo(prevState => prevState.filter(filesInfo => filesInfo.name !== fileName))
    
  };


  const viewFiles = filesInfo.map((file) => (
    <li key={file.name} className="file-item">
      <span className="file-text">{file.name}</span>
      <FontAwesomeIcon
        icon={faTrash}
        onClick={() => handleDeleteFile(file.name)}
        className="faTrash"
      />
    </li>
  ));

  const dropzoneRef = useRef<DropzoneRef>(null);

  const handleOpen = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  const [waiting, setWaiting] = useState(false);

  const history = useHistory();
  const [info, setInfo] = useState(false);

  const onChange = async () => {
    
    if (!filesInfo.length) {
      Swal.fire({
        text: "No hay archivos para procesar",
        icon: "warning",
        timer: 3000,
        showConfirmButton: false,
      });
      return;
    }
    setWaiting(true);

    const files = filesInfo;
    const customer = window.__RUNTIME_CONFIG__.CUSTOMER;
    const process = window.__RUNTIME_CONFIG__.PROCESS;

    const formData = new FormData();
    formData.append("customer", customer);
    formData.append("process", process);

    // Object.keys(files).map((i) => formData.append("file", files[i]));
    files.forEach((file) => formData.append("file", file.file));

    const req = await toZC(formData);
    const idZC = await req.data.GENERAL.ID_ZC;
    const req1 = await toZA(idZC);

    Swal.fire({
      html: `Tus documentos fueron procesados satisfactoriamente, El id es <strong>${idZC}</strong> comprueba la extracción usando tu asistente`,
      icon: "success",
      confirmButtonText: 'Aceptar',
      width: 700,
    });
    history.push("/List", { customer: 2, process: 2 });
  };


  return (
    <>
      <Upload>
        <h1>ZAT CAPTURE</h1>
        <p className="subtitle">
          Ingresa los documentos que requieres procesar
        </p>
        {info ? (
          <p>
            El módulo de extracción de ZAT Capture permite extraer campos
            estructurados para documentos que han sido entrenados. Para ello se
            extrae la información de texto que contiene la imagen y basado en
            una serie de patrones se traen campos especificos.
          </p>
        ) : !waiting ? (
          <>
            <div className="contenedorPadre">
              <div className="contenedorOne">
              <Dropzone onDrop={handleDrop}>
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps({ className: filesInfo.length > 0 ? 'dropzone active' : 'dropzone' })}>
                    <input {...getInputProps({accept: '.pdf, .jpg, .jpeg'})} />
                    <p className="subtitleDropzone">Arrastra y suelta archivos aquí</p>
                    <p className="subtitleDropzone">O</p>
                    <button className="cargar-imagen-label" type="button" onClick={handleOpen}>
                      haz click para seleccionar archivos
                    </button>
                  </div>
                )}
              </Dropzone>
              </div>
              <div className="contenedorTwo">
                <aside>
                  <h4>{filesInfo.length > 0 ? "Archivos" : ""}</h4>
                  <ul>{viewFiles}</ul>
                </aside>
              </div>
            </div>
            <div className="Buttons">
              <a
                className="cargar-imagen-labelTwo"
                onClick={onChange}
              >
                Procesar Archivos
              </a>
              <a
                className="back-to-main-menu-link"
                onClick={() => history.push("/list", { customer: 2, process: 2 })}
              >
                Volver al menú principal
              </a>
            </div>
          </>
        ) : (
          <Waiting />
        )}
        <img alt=""
          className="imgBot"
          onMouseEnter={() => setInfo(true)}
          onMouseLeave={() => setInfo(false)}
          src="https://img.icons8.com/external-bearicons-blue-bearicons/64/000000/external-question-frequently-asked-questions-faq-bearicons-blue-bearicons-4.png"
        />
      </Upload>
      <form id="form"></form>
    </>
  );
};

export default ZC;