import { Container, Divider,StyledDialog } from "./sidebarMenu.style"
import  logo from "../../../assets/images/Logo (4).png";
import  zat from "../../../assets/images/logo2.png";
import { AiOutlineLeft } from "react-icons/ai";
import { BsRobot } from "react-icons/bs";
import { MdLogout,MdLockOpen,MdOutlineTimelapse} from "react-icons/md";
import { FaUserEdit } from "react-icons/fa";
import { Link } from "react-router-dom";
import Tooltip from '@material-ui/core/Tooltip';
import { DialogTitle, Button } from '@material-ui/core';
import React, {useState} from "react";
import Card from "@mui/material/Card";
import { RiContactsFill } from "react-icons/ri";
import { TbChecklist } from "react-icons/tb";
import { useLocation } from 'react-router-dom';


interface SidebarProps {
    isOpen: boolean;
    setisOpen: React.Dispatch<React.SetStateAction<boolean>>;
    propsEmail: string;
}


export function Sidebar({isOpen, setisOpen, propsEmail}: SidebarProps ){

    const [open, setOpen] = useState(false);
    const location = useLocation();
    const isOnCompletedPage = location.pathname === '/Completados';

    const handleOpen = () => {
        setOpen(true);
      };
    
    const handleClose = () => {
        setOpen(false);
      };

    const handleProfileClick = (e: React.MouseEvent) => {
        e.preventDefault();
        handleOpen();
    }
    
    const Modsidebaropen =() =>{
        setisOpen(!isOpen);
    }

    const LinksArray= isOnCompletedPage ?
    [
        {
            label:"Zat Capture",
            icon: <BsRobot/>,
            to:"/ZC"
        },
        {
            label:"Procesos Pendientes",
            icon: <MdOutlineTimelapse/>,
            to:{ pathname: "/List", state: { customer: 2, process: 2 } },
        },
        {
            label:"Configuracion",
            icon: <RiContactsFill/>,
            onClick: handleProfileClick,
        },
    ]
:
    [
        {
            label:"Zat Capture",
            icon: <BsRobot/>,
            to:"/ZC"
        },
        {
            label:"Procesos Completos",
            icon: <TbChecklist/>,
            to:{ pathname: "/Completados", state: { customer: 2, process: 2 } },
        },
        {
            label:"Configuracion",
            icon: <RiContactsFill/>,
            onClick: handleProfileClick,
        },
    ];
    
    const SeconlinksArray=[
        {
            label:"Salir",
            icon: <MdLogout/>,
            to:"/"
        }
    ]


    return (
        <Container isOpen={isOpen}>
            <button className="Sidebarbutton" onClick={Modsidebaropen}>
                <AiOutlineLeft />
            </button>
            <div className="Logocontent">
                <div className="img-content">
                    <img src={logo} alt=""/>
                </div>
                <h2>
                    Menu
                </h2>
            </div>
            {LinksArray.map(({icon,label,to, onClick})=>(
                <div className="Linkcontent" key={label} >
                    <Link to={to} className="Linkstext" onClick={onClick} >
                    <Tooltip title={label} placement="right">
                        <div className="Linkicon">
                            {icon}
                        </div>
                    </Tooltip>
                        {isOpen &&(
                            <span>{label}</span>
                        )

                        }
                    </Link>
                </div>
            ))}

            <StyledDialog open={open} onClose={handleClose}>
                <div className="MuiDialog-paper">
                    <Card>
                        <div className="SecondaryCard">
                        <DialogTitle>
                            <img src={zat} alt="" className="myIconFeedback"/>
                            <div className="nameContainer">
                                <p className="userTitle">Usuario</p>
                                <p className="email">{propsEmail}</p> 
                            </div>
                        </DialogTitle>

                        <div className="buttonContainer">
                        <Link to="/Password">
                                <button className="changePasswordButton">
                                    <MdLockOpen className="iconchangepassword"/>
                                    Cambiar contraseña
                                </button>
                            </Link>
                        </div>
                            {/* <DialogContent>
                            </DialogContent> */}
                        </div>
                    </Card>
                </div>
                    <Button onClick={handleClose} className="CeClose">Cerrar Ajustes</Button>
            </StyledDialog>

            <Divider isOpen={isOpen}/>

            {SeconlinksArray.map(({icon,label,to })=>(
                <div className="Linkcontent2" key={label}>
                    <Link to={to} className="Linkstext2">
                        <Tooltip title={label} placement="right">
                            <div className="Linkicon2">
                                {icon}
                            </div>
                        </Tooltip>
                        {isOpen &&(
                            <span>{label}</span>
                        )

                        }
                    </Link>
                </div>
            ))}
        </Container>
    );
}






