import React, { useState, useEffect } from "react";
import { StudentInfo } from "../../types/Student";
import OCR from "../documents/OCR";
import { Form, Docs } from "./Editing.styles";
import Button from "../layout/Button";
import { blue, light_blue, pink } from "../../App.styles";
import { TextField } from "@material-ui/core";
import Reclasification from "./Reclasification";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faSearchPlus, faSearchMinus, faBook, faCheck } from "@fortawesome/free-solid-svg-icons";
import ImgsComplete from "../documents/ImgsComplete";

type Props = {
  values: StudentInfo;
  onChange: (key: string, value: string) => void;
  ChangeTipo: (r: any) => void;
  view: boolean;
};

const EditingComplete = ({ values, onChange, ChangeTipo, view }: Props) => {
  const ocr = values["TEXTO_EXTRAIDO"];
  const image = values["NOMBRE_IMAGEN"];
  const [zoom, setZoom] = useState(1);

  const inputs = Object.keys(values).filter(
    (val) =>
      !["TEXTO_EXTRAIDO", "NOMBRE_IMAGEN", "ID_NODO_RELACIONADO"].includes(val)
  );

  const handleChange = (e: any) => {
    const event = e.target;
    const key = event.parentElement.previousElementSibling.innerText.trim();
    const value = event.value;
    onChange(key, value);
  };

  const handleChangeTipo = async (e: any) => {
    const NEW_TIPO = e.target.innerText;
    const r = [
      (({
        NOMBRE_IMAGEN,
        NOMBRE_ARCHIVO,
        ID_NODO_RELACIONADO,
        TEXTO_EXTRAIDO,
      }) => ({
        NOMBRE_IMAGEN,
        NEW_TIPO,
        NOMBRE_ARCHIVO,
        ID_NODO_RELACIONADO,
        TEXTO_EXTRAIDO,
      }))(values),
    ];
    ChangeTipo(r);
    setChangeClass(!changeClass);
  };

  const createForms = inputs.map((label, index) => (
    <TextField
      label={label}
      value={values[label] ? values[label] : " "}
      onChange={handleChange}
      className={values[label]==='' ?  "form emptyform" : "form" }
      disabled={true}
      size="small"
    />
  ));

  const [isImage, SetIsImage] = useState(true);

  const change = () => {
    SetIsImage(!isImage);
  };

  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    setShowButton(false); 
  }, [image]);

  const zoomIn = () => {
    if (zoom < 2.5) { 
      setZoom(zoom + 0.2);
    }
  };
  
  const zoomOut = () => {
    if(zoom > 0.7) { 
      setZoom(zoom - 0.1); 
    }
  };

  const setDocs = (buttonText: string, fontIcon: any, doc: JSX.Element, thirdButton?: JSX.Element) => {
    return (
      <Docs>
        <div>
          <Button
            bcolor={blue}
            icon={fontIcon}
            text={buttonText}
            onClick={change}
          />
          {isImage && (
            <>
          <FontAwesomeIcon
            icon={faSearchPlus}
            onClick={zoomIn}
            className="zoom-icon"
          />
          <FontAwesomeIcon
            icon={faSearchMinus}
            onClick={zoomOut}
            className="zoom-icon"
          />
          </>
          )}
          {thirdButton}
        </div>
        {doc}
      </Docs>
    );
  };
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard =()=> {
    navigator.clipboard.writeText(ocr).then(function () {
      setIsCopied(true);
      setTimeout(()=>{
        setIsCopied(false);
      },3000);
    }, function(err){
      console.error('Async: Could not copy text: ', err);
    });
  };


  const copyButton =(
      <Button
      bcolor={pink}
      color="white"  
      icon={isCopied? faCheck : faBook}  
      text={isCopied ? "Texto copiado" : "Copiar texto"}
      onClick={copyToClipboard}
    />
  );



  const [changeClass, setChangeClass] = useState(true);
  const changeButton = changeClass
    ? [
        "Cambiar Tipo",
        "exchange-alt",
        "Información a Editar",
        "Al editar la información, sobreescribirá los datos capturados",
      ]
    : [
        "Editar",
        "edit",
        "Cambiar Tipo Documento",
      ];

  const handleClick = () => {
    setChangeClass(!changeClass);
  };

  return (
    <>
      <Form>
        <h2>{changeButton[2]}</h2>
        <p>{changeButton[3]}</p>

        <div className="inputtext">
          {changeClass ? (
            <div className="editForm">{createForms}</div>
          ) : (
            <Reclasification onClick={handleChangeTipo} />
          )}
        </div>
        <div className="formbutton">
        </div>
      </Form>

      {isImage
        ? setDocs(
            "Vista Texto",
            "file-alt",
            <ImgsComplete key={image} image={image} zoom={zoom} showButton={showButton} />
          )
          
        : setDocs("Vista Imagen", "image", <OCR ocr={ocr} />, copyButton)}
        
    </>
  );
};

export default EditingComplete;
