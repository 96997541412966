// import React, { useContext } from "react";
import { Nav } from "./Navbar.styles";
import logo from "../../assets/images/zatcapture.jpg";
// import Button from "./Button";
// import { light_blue } from "../../App.styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { Link } from 'react-router-dom';

// import { authToken } from "../../context/Context";

// import { useHistory } from "react-router-dom";

const Navbar = () => {
  // const history = useHistory();
  // const { token } = useContext(authToken);
  // const text = token ? "Salir" : "Entrar";

  return (
    <>
      <Nav>
        <a>
          <img className="nav-logo" src={logo} alt="" />
        </a>
        <div className="capture">
          <a>
            <FontAwesomeIcon icon="fingerprint" className="fingerprint" />
          </a>
          <h3>ASSISTANT</h3>
        </div>
        <b className="complemento"
        // se utiliza esta b para mantener bien centrado el logo y palabra ASSISTANT 
        />
      </Nav>
    </>
  );
};

export default Navbar;
