import { zc, zc2 } from "./Intances";



export const toZC = (files: any) => zc.post('main_process',
    files)

export const ReprocessImages = async (file: any): Promise<any> => {
  try {
      const response = await zc2.post('main_process', file);
      return response;
  } catch (error) {
      console.error("Hubo un error al hacer la petición:", error);
      throw error;
  }
}


export const toZA = (id: string) => 
zc.post(`update_request_status?id_zc=${id}&version_zc=0&reintento=0&estado=PENDIENTE`)