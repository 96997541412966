import styled from "styled-components";
import { blue, pink } from "../../App.styles";

export const Nav = styled.div`
  padding: 1.5rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 1;
  box-shadow: 0 10px 10px -2px rgba(0, 0, 0, 0.1);

  .capture {
    color: ${blue};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 2;
    

    h3 {
      font-size: 1.5rem;
      letter-spacing: 0.25rem;
      padding-left: 1rem;
    }
    .fingerprint {
      color: ${pink};
      font-size: 1.75rem;
    }
  }
  .nav-logo {
    height: 4rem;
    
  }

  .complemento{
    padding: 0.75rem 5rem;
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
`;
