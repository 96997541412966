import React, { useState, useEffect} from 'react'
import { Container, Edit} from './Assistant.styles'
import Editing from '../../components/editing/Editing'
import Sidebar from '../../components/layout/Sidebar'
import { Student } from '../../types/Student'
import {modifyInfo,reclassify,liberate} from '../../requests/Data'
import {useHistory,useLocation} from 'react-router-dom'
import update from 'immutability-helper';
import { reclassifyClasses } from '../../context/Context';

const Assistant = () => {


    const location = useLocation()

    const a = !location.state ?{'json':{'Error':''},'id':'','classes':''}: location.state

    const [user, setUser] = useState<Student>(a.json)
    const id =a.ui_request
    const classes=a.classes
    var  process_status_detail="";
    process_status_detail=a.process_status_detail;
    var formlabelstmp="";

    if (process_status_detail ==null)
        process_status_detail=""

    const history = useHistory()

    const labels = Object.keys(user)

    useEffect(() => {
        labels[0]=='Error'? history.push('/error',{'error':''}):console.log('')
        
    }, [user]);



    const [view,setView]=useState(true)

    function documenthide(key:string){      
        var value=0;
         try {
             var tipo=(Object.entries(user).filter(x=> x[0]==key)[0][1]).TIPO;
             var splitted=process_status_detail.split(",");
         
             splitted.forEach(element => {
             if((!element.toUpperCase().includes('NONE')&&!element.toUpperCase().includes('ENTREGADO')&&!element.toUpperCase().includes('EXITOSO')) && (element.includes(tipo)))
             {
                 value=1;
                 return;
             }
             });
             if (process_status_detail=="")
                 value=1
         } catch (error) {
             return 1;
         };
      return value;
     } 
    
    const sidebarKeys = labels.map((key, index) => <p key={index} className={key}
        onClick={()=>{setFormLabels(key);setView(!view)}} style={{display : documenthide(key)==1?'inline':'none'}}>{key}</p>)
        
        try {
            formlabelstmp=sidebarKeys.filter(x=> x.props.style.display=='inline')[0].props.className; 
        } catch (error) {
            formlabelstmp=labels[0];
        }
      
    
      const [formlabels, setFormLabels] = useState(formlabelstmp)        
    
    useEffect(() => {
         document.getElementsByClassName(formlabels)[0].classList.add('selectedKeys')
         labels.filter(i=>i!=formlabels).map(i=>document.getElementsByClassName(i)[0].classList.remove('selectedKeys'))
 
     }, [formlabels]);

    const handleChange = (key: string, value: string) => {

        setUser(prevState => ({
            ...prevState,
            [formlabels]: { ...prevState[formlabels], [key]: value }
        }));

    }

    const handleSubmit=async(e:any)=>{

        if (e.target.innerHTML=='Descargar Respuesta'){
            alert('Para más información por favor contacta a contacto@2nv.co')
        }
        else if(e.target.innerHTML=='Ver NO CLASIFICADOS'){
            e.target.innerHTML="Ocultar NO CLASIFICADOS";       
                for ( var i = 0; i < document.querySelectorAll('[class*="NO CLASIFICADOS"]').length; ++i )
                (document.querySelectorAll('[class*="NO CLASIFICADOS"]')[i] as HTMLInputElement).style.display = 'inline';           
        }
        else if(e.target.innerHTML=='Ocultar NO CLASIFICADOS'){
            e.target.innerHTML="Ver NO CLASIFICADOS";       
                 for ( var i = 0; i < document.querySelectorAll('[class*="NO CLASIFICADOS"]').length; ++i )
                 (document.querySelectorAll('[class*="NO CLASIFICADOS"]')[i] as HTMLInputElement).style.display = 'none';

                 try {
                    (document.querySelectorAll('[style*="display: inline"]')[0] as HTMLInputElement).click();
                 } catch (error) {
                    (document.querySelectorAll('[style*="display: none"]')[0] as HTMLInputElement).click(); 
                 }
        }else{
            e.target.innerHTML=='Finalizar y Guardar'? await modifyInfo(id,user) :  await liberate(id)     
            history.goBack({ 'customer': 2, 'process': 2 })
        }
    }


    const [newUser,setnewUser]=useState(user) 
    const [lastInitNumber, setLastInitNumber] = useState(0);

    const handleChangeTipo =async(r:any)=>{
    const req =await reclassify(r,id,process_status_detail)
    const newClass=await req.data
    const k=Object.keys(newClass)[0]
  

    let updateKey = k;

    if (k === "0") {
        let num = lastInitNumber + 1;
        
        newClass[num] = newClass[k];
        delete newClass[k];
        updateKey = num.toString();
        setLastInitNumber(num); 
    }
      
    const newState=update(user,{
        [formlabels]:{$set:newClass[updateKey]}
    })

    setnewUser(Object.keys(newState).reduce((a, key) => ({
        ...a,
        [key === formlabels ?updateKey: key]: newState[key],
      }), {}))

      setFormLabels(updateKey);
    }

    useEffect(() => {
    setUser(newUser)
    },[newUser])
    
    const allow=window.__RUNTIME_CONFIG__.ALLOW_DOWNLOAD
    var buttons=['']; 
    if(labels.filter(x=> x.includes('NO CLASIFICADOS')).length>0){
        buttons= allow!='true'? ['Finalizar y Guardar','Volver sin Guardar','Ver NO CLASIFICADOS']  :['Finalizar y Guardar','Volver sin Guardar','Descargar Respuesta','Ver NO CLASIFICADOS'] 
    }else{
        buttons= allow!='true'? ['Finalizar y Guardar','Volver sin Guardar']  :['Finalizar y Guardar','Volver sin Guardar','Descargar Respuesta'] 
    }


    return (

    <reclassifyClasses.Provider value={classes}>
 
            <Container>
                <Sidebar sidebarKeys={sidebarKeys} title='DOCUMENTOS' buttons={buttons} onSubmit={handleSubmit}/>
                <Edit>
                    <Editing values={user[formlabels]} onChange={handleChange} ChangeTipo={handleChangeTipo} view={view}/>
                </Edit> 
            </Container>
    </reclassifyClasses.Provider>
    )
    }



export default Assistant



